import {
    Card,
    Dialog,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    styled,
    tooltipClasses,
} from "@mui/material";
import { DataGrid, GridEditInputCell } from "@mui/x-data-grid";
import { forwardRef, useCallback, useState } from "react";
import {
    getFilterQueryObject,
    getGridOperators,
    translateString,
} from "../utils";
import { useFetch, useSnackbar } from "../../hooks";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import { BACKEND_ROUTES } from "../../backendRoutes";
import { CompanyForm } from "../company";
import { CompanyTableSkeleton } from "./CompanyTableSkeleton";
import { FRONTEND_ROUTES } from "../../frontendRoutes";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { Link } from "react-router-dom";
import Scrollbar from "../../components/Scrollbar";
import { formatDateISO } from "../../utils/formatTime";
import { laggy } from "../../utils/SWRLaggy";
import { useKcRole } from "../../hooks/useKcRole";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

const pageItemCount = 100;

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
}));

const CustomGridEditInputCell = forwardRef((props, ref) => (
    <GridEditInputCell {...props} ref={ref} />
));

function NameEditInputCell({ error, ...props }) {
    return (
        <StyledTooltip open={Boolean(error)} title={error || ""}>
            <CustomGridEditInputCell {...props} />
        </StyledTooltip>
    );
}

function renderEditName(params) {
    return <NameEditInputCell {...params} />;
}

export function CompanyTable() {
    const { isBasicRole } = useKcRole();

    const [page, setPage] = useState(0);
    const [sortState, setSortState] = useState([
        { field: "name", sort: "asc" },
    ]);
    const [filterState, setFilterState] = useState({
        items: [{ field: "", operator: "", value: "" }],
    });

    const { openSnackbar } = useSnackbar();
    const [isOpen, setIsOpen] = useState(false);
    const { patch } = useFetch();
    const closeModal = () => {
        setIsOpen(false);
    };

    const handleProcessRowUpdateError = useCallback(
        (error) => {
            openSnackbar(error.message, "error");
        },
        [openSnackbar]
    );

    const { data: regions, error: regionsFetchError } = useSWRImmutable(
        BACKEND_ROUTES.REGION
    );

    const { data: segments, error: segmentsFetchError } = useSWRImmutable(
        BACKEND_ROUTES.SEGMENT
    );

    let searchParamsObject = {
        includeLastContract: "true",
        limit: pageItemCount,
        offset: page * pageItemCount,
    };

    if (sortState[0]) {
        searchParamsObject["sort"] = `${
            sortState[0].sort === "desc" ? "-" : ""
        }${translateString(sortState[0].field, {
            segment: "Segment_name",
            region: "Region_name",
        })}`;
    }

    if (filterState.items[0]?.value) {
        const translatedField = translateString(filterState.items[0].field, {
            segment: "segmentUuid",
            region: "regionUuid",
        });
        searchParamsObject = {
            ...searchParamsObject,
            ...getFilterQueryObject(
                translatedField,
                filterState.items[0].operator,
                filterState.items[0].value
            ),
        };
    }

    const {
        data: companies,
        error: companiesFetchError,
        mutate,
        isLagging,
    } = useSWR(
        `${BACKEND_ROUTES.COMPANY}?${new URLSearchParams(searchParamsObject)}`,
        { use: [laggy] }
    );

    const processRowUpdate = useCallback(
        async (newRow, oldRow) => {
            const updatedCompany = await patch(
                `${BACKEND_ROUTES.COMPANY}/${newRow.uuid}`,
                {
                    body: {
                        ...newRow,
                        segmentUuid: newRow.segment,
                        regionUuid: newRow.region,
                    },
                    forwardError: true,
                }
            );
            if (updatedCompany) {
                openSnackbar(`Updated ${newRow.name} successfully.`, "success");
                mutate();
                return newRow;
            }
            return oldRow;
        },
        [mutate, openSnackbar, patch]
    );

    const mergedFetchError =
        companiesFetchError ?? regionsFetchError ?? segmentsFetchError;

    if (mergedFetchError && !companies)
        return <FetchErrorAlert error={mergedFetchError} />;

    if (!companies || !regions || !segments) {
        return <CompanyTableSkeleton />;
    }

    const gridRows = companies.rows.map((company) => {
        return {
            id: company.uuid,
            uuid: company.uuid,
            name: company.name,
            lastContract: company.Contracts?.[0]
                ? formatDateISO(company.Contracts[0].createdAt)
                : "No Campaign yet",
            acceptedEmailHandles: company.acceptedEmailHandles,
            segment: company.Segment.uuid,
            region: company.Region.uuid,
        };
    });

    // DataGrid column definitions
    const gridColDef = [
        {
            field: "actions",
            headerName: "",
            sortable: false,
            hideable: false,
            filterable: false,
            width: 65,
            flex: 0.5,
            disableClickEventBubbling: true,
            renderCell: (params) => (
                <Tooltip title="Go to Campaigns">
                    <IconButton
                        sx={{
                            "&:hover": {
                                backgroundColor: "primary.lighter",
                            },
                        }}
                        component={Link}
                        to={`/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT}?companyUuid=${params.row.id}`}
                    >
                        <ArticleOutlinedIcon fontSize="small" color="primary" />
                    </IconButton>
                </Tooltip>
            ),
        },
        {
            field: "name",
            headerName: "Company",
            minWidth: 300,
            flex: 3,
            editable: !isBasicRole,
            filterOperators: getGridOperators("string"),
            preProcessEditCellProps: (param) => {
                const nullNameError =
                    param.props.value.length < 1
                        ? "Company's name cannot be null."
                        : undefined;
                return {
                    ...param.props,
                    error: nullNameError,
                };
            },
            renderEditCell: renderEditName,
        },
        {
            field: "lastContract",
            headerName: "Last campaign created on",
            minWidth: 180,
            flex: 2,
            sortable: false,
            filterable: false,
        },
        {
            field: "segment",
            headerName: "Segment",
            minWidth: 180,
            flex: 2,
            editable: !isBasicRole,
            type: "singleSelect",
            filterOperators: getGridOperators("select"),
            valueOptions: segments.rows.map((segment) => ({
                value: segment.uuid,
                label: segment.name,
            })),
        },
        {
            field: "region",
            headerName: "Region",
            minWidth: 180,
            flex: 2,
            editable: !isBasicRole,
            type: "singleSelect",
            filterOperators: getGridOperators("select"),
            valueOptions: regions.rows.map((region) => ({
                value: region.uuid,
                label: region.name,
            })),
        },
        {
            // TODO: change editable to true and make a custom component for it (in another branch, likely)
            field: "acceptedEmailHandles",
            headerName: "Email handles",
            minWidth: 250,
            flex: 3,
            editable: false,
            sortable: false,
            filterOperators: getGridOperators("string", ["contains"]),
            valueFormatter: (param) => param.value.join(", "),
        },
    ];

    return (
        <>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Typography variant="h4" gutterBottom>
                    Companies
                </Typography>
                <Stack direction="row" spacing={1}>
                    {!isBasicRole && (
                        <IconButton
                            size="large"
                            onClick={() => setIsOpen(true)}
                        >
                            <AddCircleOutlinedIcon
                                fontSize="inherit"
                                color="primary"
                            />
                        </IconButton>
                    )}
                    <Dialog open={isOpen} fullWidth maxWidth="sm">
                        <CompanyForm closeModal={closeModal} mutate={mutate} />
                    </Dialog>
                </Stack>
            </Stack>

            <Card>
                <Scrollbar>
                    <DataGrid
                        autoHeight
                        rows={gridRows}
                        columns={gridColDef}
                        processRowUpdate={processRowUpdate}
                        onProcessRowUpdateError={handleProcessRowUpdateError}
                        pageSizeOptions={[pageItemCount]}
                        initialState={{
                            sorting: {
                                sortModel: sortState,
                            },
                        }}
                        paginationMode="server"
                        paginationModel={{ page, pageSize: pageItemCount }}
                        onPaginationModelChange={(newPageModel) => {
                            setPage(newPageModel.page);
                        }}
                        rowCount={companies.count}
                        sortingMode="server"
                        onSortModelChange={setSortState}
                        filterMode="server"
                        onFilterModelChange={setFilterState}
                        loading={isLagging}
                    />
                </Scrollbar>
            </Card>
        </>
    );
}
