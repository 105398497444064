import { BE_RESOURCE_ACCESS, KC_ROLES } from "../constants";

import { useOidcAccessToken } from "@axa-fr/react-oidc";

export function useKcRole() {
    const { accessTokenPayload } = useOidcAccessToken();

    const roles =
        accessTokenPayload?.resource_access[BE_RESOURCE_ACCESS]?.roles;

    // Return the role with the most privilege
    // ⚠️ Will always return at least KC_ROLES.BASIC
    let myRole = KC_ROLES.BASIC;
    if (roles) {
        if (roles.includes(KC_ROLES.ADMIN)) myRole = KC_ROLES.ADMIN;
        else if (roles.includes(KC_ROLES.BACKOFFICE))
            myRole = KC_ROLES.BACKOFFICE;
    }

    return {
        kcRole: myRole,
        isBasicRole: myRole === KC_ROLES.BASIC,
    };
}
