/* eslint-disable no-underscore-dangle */
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "./leafletIcons.css";

import { useEffect, useState } from "react";

import { PARCELLAIRE_REDUCER_ACTIONS } from "./parcellaireReducer";
import { geomanMarkerIcon } from "./utils/geomanMarkerIcon";
import { removeAllCoordinateMarkers } from "./utils/removeAllCoordinateMarkers";
import { roundNumber } from "../utils/roundNumber";
import { useLeafletContext } from "@react-leaflet/core";

export const Geoman = ({
    parcellaireState,
    parcellaireDispatch,
    setGeoJsonKey,
    setValue,
    reset,
    coordMarkerMode,
    setCoordMarkerMode,
}) => {
    const [addedControls, setAddedControls] = useState(false);
    const context = useLeafletContext();
    const leafletContainer = context.layerContainer || context.map;

    useEffect(() => {
        if (leafletContainer && !addedControls) {
            leafletContainer.pm.Toolbar.copyDrawControl("Polyline", {
                name: "polylineMarkDraw",
                block: "options",
                title: "Draw line marker",
                // afterClick toggles drawing for this specific button (it's the default behaviour), but also toggles dragMode when the button is untoggled
                afterClick: (_, context) => {
                    leafletContainer.pm.Draw[
                        context.button._button.jsClass
                    ].toggle();

                    if (!context.button._button.toggleStatus) {
                        leafletContainer.pm.enableGlobalDragMode();
                    }
                },
                actions: [],
            });

            leafletContainer.pm.Toolbar.copyDrawControl("Polygon", {
                name: "polygonSelect",
                block: "draw",
                title: "Select (polygon)",
                afterClick: (_, context) => {
                    leafletContainer.pm.Draw[
                        context.button._button.jsClass
                    ].toggle();

                    if (!context.button._button.toggleStatus) {
                        leafletContainer.pm.enableGlobalDragMode();
                    }
                },
                actions: [],
            });

            leafletContainer.pm.Toolbar.createCustomControl({
                name: "polygonDelete",
                block: "draw",
                title: "Delete selection",
                className: "leaflet-pm-icon-trash",
                onClick: () => {
                    leafletContainer.pm
                        .getGeomanLayers()
                        .filter(
                            (layer) => layer.options.customType === "idLabel"
                        )
                        .forEach((layer) => {
                            layer.remove();
                        });
                    parcellaireDispatch({
                        type: PARCELLAIRE_REDUCER_ACTIONS.DELETE,
                    });
                    setGeoJsonKey(new Date().getTime());
                },
                afterClick: () => {
                    leafletContainer.pm.enableGlobalDragMode();
                },
                toggle: false,
            });

            leafletContainer.pm.Toolbar.createCustomControl({
                name: "polygonLock",
                block: "draw",
                title: "Lock selection",
                className: "leaflet-pm-icon-lock",
                onClick: () => {
                    parcellaireDispatch({
                        type: PARCELLAIRE_REDUCER_ACTIONS.LOCK,
                    });
                    setGeoJsonKey(new Date().getTime());
                },
                afterClick: () => {
                    leafletContainer.pm.enableGlobalDragMode();
                },
                toggle: false,
            });

            leafletContainer.pm.Toolbar.createCustomControl({
                name: "polygonUnlock",
                block: "draw",
                title: "Unlock all",
                className: "leaflet-pm-icon-unlock",
                onClick: () => {
                    parcellaireDispatch({
                        type: PARCELLAIRE_REDUCER_ACTIONS.UNLOCK,
                    });
                    setGeoJsonKey(new Date().getTime());
                },
                toggle: false,
            });

            leafletContainer.pm.Toolbar.createCustomControl({
                name: "unselect",
                block: "edit",
                title: "Save checkpoint & Unselect",
                className: "leaflet-pm-icon-save",
                onClick: () => {
                    parcellaireDispatch({
                        type: PARCELLAIRE_REDUCER_ACTIONS.CHECKPOINT,
                    });
                    setGeoJsonKey(new Date().getTime());
                },
                toggle: false,
            });

            leafletContainer.pm.Toolbar.copyDrawControl("Marker", {
                name: "coordMarkerDraw",
                block: "options",
                title: "Place coordinates marker",
                afterClick: (_, context) => {
                    removeAllCoordinateMarkers(leafletContainer);
                    leafletContainer.pm.Draw[
                        context.button._button.jsClass
                    ].toggle();

                    if (!context.button._button.toggleStatus) {
                        leafletContainer.pm.enableGlobalDragMode();
                    }
                },
                actions: [],
            });

            leafletContainer.pm.Toolbar.createCustomControl({
                name: "markerReset",
                block: "options",
                title: "Removes all line markers",
                className: "leaflet-pm-icon-delete",
                onClick: () => {
                    leafletContainer.pm
                        .getGeomanLayers()
                        .filter(
                            (layer) => layer.options.customType === "marker"
                        )
                        .forEach((layer) => {
                            layer.remove();
                        });
                    leafletContainer.pm.enableGlobalDragMode();
                },
                toggle: false,
            });

            leafletContainer.pm.Toolbar.createCustomControl({
                name: "softReset",
                block: "options",
                title: "Reset to last checkpoint",
                className: "leaflet-pm-icon-soft-reset",
                onClick: () => {
                    parcellaireDispatch({
                        type: PARCELLAIRE_REDUCER_ACTIONS.GO_TO_CHECKPOINT,
                    });
                    setGeoJsonKey(new Date().getTime());
                },
                toggle: false,
            });

            setAddedControls(true);

            leafletContainer.on("pm:create", (e) => {
                if (e.shape === "polygonSelect") {
                    // If there is a selection
                    const geoJsonSelect = e.layer.toGeoJSON();

                    const allLayers = leafletContainer.pm.getGeomanLayers();

                    parcellaireDispatch({
                        type: PARCELLAIRE_REDUCER_ACTIONS.SELECT,
                        e,
                        geoJsonSelect,
                        allLayers,
                    });

                    setGeoJsonKey(new Date().getTime());

                    e.layer.remove();
                } else if (e.shape === "coordMarkerDraw") {
                    // If this is a coordinate marker
                    setCoordMarkerMode(false);
                    setValue("latitude", roundNumber(e.layer._latlng.lat, 8), {
                        shouldDirty: true,
                    });
                    setValue("longitude", roundNumber(e.layer._latlng.lng, 8), {
                        shouldDirty: true,
                    });
                    reset((formValues) => ({
                        ...formValues,
                    }));
                } else {
                    leafletContainer.pm.enableGlobalDragMode();
                }
            });
        }

        leafletContainer.pm.addControls({
            drawMarker: false,
            drawPolyline: false,
            drawCircle: false,
            drawCircleMarker: false,
            drawRectangle: false,
            drawPolygon: false,
            drawText: false,
            coordMarkerDraw: false,
            editMode: false,
            dragMode: false,
            cutPolygon: false,
            removalMode: false,
            rotateMode: false,
            // positions: { options: "bottomleft" }, // changes position of specific control block
        });

        // adds options on polylineMarkDraw that we draw
        // the color option makes the polylineMarkDraw red, the customType gives it a custom type that is used for clearing
        leafletContainer.pm.enableDraw("polylineMarkDraw", {
            pathOptions: {
                color: "red",
                customType: "marker",
                interactive: false,
            },
        });

        leafletContainer.pm.setGlobalOptions({
            snappable: false,
            pmIgnore: false,
            markerStyle: {
                icon: geomanMarkerIcon,
                interactive: false,
            },
        });

        leafletContainer.pm.enableGlobalDragMode();

        if (coordMarkerMode) {
            removeAllCoordinateMarkers(leafletContainer);
            leafletContainer.pm.enableDraw("coordMarkerDraw", {});
        }

        return () => {
            leafletContainer.pm.removeControls();
            leafletContainer.pm.setGlobalOptions({
                pmIgnore: true,
                snappable: true,
            });
        };
    }, [
        addedControls,
        leafletContainer,
        parcellaireState,
        setGeoJsonKey,
        parcellaireDispatch,
        setValue,
        reset,
        coordMarkerMode,
        setCoordMarkerMode,
    ]);

    return null;
};
