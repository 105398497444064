import { MissionProtocolSensorData, MissionProtocolStageTableLiteral } from ".";

import { Grid } from "@mui/material";

export function MissionProtocolLiteral({
    pipelineTemplate,
    traitGroupDataTypeArrays,
}) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <MissionProtocolSensorData
                    pipelineTemplate={pipelineTemplate}
                    showOnlyCropSystemModel
                />
            </Grid>

            <Grid item xs={8}>
                <MissionProtocolStageTableLiteral
                    traitGroupDataTypeArrays={traitGroupDataTypeArrays}
                />
            </Grid>
        </Grid>
    );
}
