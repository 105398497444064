import { PAGINATION, SYSTEMS } from "../../constants";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { ContractDetailsSkeleton } from "./ContractDetailsSkeleton";
import { ContractForm } from "./ContractForm";
import { Divider } from "@mui/material";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { PipelineTemplateTable } from "../pipeline-template/PipelineTemplateTable";
import { SiteTable } from "../site";
import { Stack } from "@mui/system";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";

export const ContractDetails = () => {
    const { uuid } = useParams();

    const {
        data: contract,
        error: contractFetchError,
        mutate: mutateContract,
    } = useSWR(`${BACKEND_ROUTES.CONTRACT}/${uuid}?parentInfo=true`);

    const {
        data: pipelineTemplateData,
        error: pipelineTemplateFetchError,
        mutate: mutatePipelineTemplate,
    } = useSWR(
        `${BACKEND_ROUTES.PIPELINE_TEMPLATE}?parentInfo=true&calendars=true&contractUuid=${uuid}&limit=${PAGINATION.GENERIC.LIMIT.MAX}`
    );

    const hasLiteralTemplates = useMemo(
        () =>
            pipelineTemplateData?.rows.some(
                (pipeline) =>
                    pipeline.AcquisitionVector.SystemModel.system ===
                    SYSTEMS.LITERAL
            ),
        [pipelineTemplateData]
    );

    const mergedFetchError = contractFetchError ?? pipelineTemplateFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (!contract || !pipelineTemplateData) return <ContractDetailsSkeleton />;

    return (
        <Stack spacing={2}>
            <ContractForm contract={contract} mutate={mutateContract} />
            <Divider />
            <PipelineTemplateTable
                contractUuid={uuid}
                pipelineTemplateData={pipelineTemplateData}
                mutate={mutatePipelineTemplate}
            />
            <Divider />
            <SiteTable
                contract={contract}
                hasLiteralTemplates={hasLiteralTemplates}
            />
        </Stack>
    );
};
