import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CodeOffIcon from "@mui/icons-material/CodeOff";
import FilterCenterFocusIcon from "@mui/icons-material/FilterCenterFocus";
import GrassIcon from "@mui/icons-material/Grass";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import LensBlurIcon from "@mui/icons-material/LensBlur";
import WbSunnyIcon from "@mui/icons-material/WbSunny";

export const DEFAULT_ERROR_MESSAGE =
    "An error occurred, please try again in a few minutes.";
export const NOT_FOUND_ERROR_MESSAGE = "Not found.";

/**
 * Api limits
 */
export const PAGINATION = Object.freeze({
    GENERIC: {
        LIMIT: {
            DEFAULT: 100,
            MAX: 200,
        },
    },
    COMPANY: {
        LIMIT: {
            DEFAULT: 500,
            MAX: 5000,
        },
    },
    CONTRACT: {
        LIMIT: {
            DEFAULT: 500,
            MAX: 5000,
        },
    },
    PLANT_SCALE: {
        LIMIT: {
            DEFAULT: 500,
            MAX: 5000,
        },
    },
    PROCESS: {
        LIMIT: {
            DEFAULT: 100,
            MAX: 200,
        },
    },
    FEATURE: {
        LIMIT: {
            DEFAULT: 500,
            MAX: 5000,
        },
    },
    OUTPUT_DATA: {
        LIMIT: {
            DEFAULT: 500,
            MAX: 5000,
        },
    },
    RAW_DATASET: {
        LIMIT: {
            DEFAULT: 500,
            MAX: 5000,
        },
    },
    OUTPUT_BINARY_DATA: {
        LIMIT: {
            DEFAULT: 500,
            MAX: 5000,
        },
    },
    SENSOR: {
        LIMIT: {
            DEFAULT: 500,
            MAX: 5000,
        },
    },
    ACQUISITION_VECTOR: {
        LIMIT: {
            DEFAULT: 500,
            MAX: 5000,
        },
    },
    TRAIT: {
        LIMIT: {
            DEFAULT: 500,
            MAX: 5000,
        },
    },
});

/**
 * Pre-processing Modules
 */
export const ALIGNMENT_MODULE_NAME = "Photogrammetry";

/**
 * PDF name produced by the Photogrammetry module
 */
export const ALIGNMENT_REPORT_FILE_NAME = "alignment_report.pdf";

/**
 * Processing Modules
 */
export const PROCESSING_MODULES = Object.freeze({
    "canopyfcover-av-rgbi-imgproc": {
        VALIDATION_PAGE: "fcover_validation.html",
        TREND_VALIDATION: true,
    },
    "canopyfcover-nov-rgbi-imgproc": {
        VALIDATION_PAGE: "fcover_validation.html",
        TREND_VALIDATION: true,
    },
    "canopysegmentation-nov-rgbi": {
        VALIDATION_PAGE: "fcover_validation.html",
        TREND_VALIDATION: true,
    },
    "plantcounting-av-rgbi-ml": {
        VALIDATION_PAGE: "plantcounting_validation.html",
        TREND_VALIDATION: false,
    },
    "objectcounting-nov-rgbi-ml": {
        VALIDATION_PAGE: "objectcounting_validation.html",
        TREND_VALIDATION: false,
    },
    "rowdetection-av-rgbi-imgproc": {
        VALIDATION_PAGE: "rowdetection_validation.html",
        TREND_VALIDATION: false,
    },
    "imageselection-av-rgbi-imgproc": {
        VALIDATION_PAGE: "imageselection_validation.html",
        TREND_VALIDATION: false,
    },
    "orthomanualselection-nov-nos": {
        VALIDATION_PAGE: "imageselection_validation.html",
        TREND_VALIDATION: false,
    },
    "MS inversion": {
        VALIDATION_PAGE: "veg_indices_validation.html",
        TREND_VALIDATION: true,
    },
    "plantheight-av-rgbi-sfm": {
        VALIDATION_PAGE: "plantheight_validation.html",
        TREND_VALIDATION: true,
    },
});

/**
 * DB model enums
 */
export const VALIDATION_STATUS = Object.freeze({
    VALIDATED: "Validated",
    REJECTED: "Rejected",
});

export const PROCESS_STATUS = Object.freeze({
    UNPLANNED: "Unplanned",
    PLANNED: "Planned",
    EXECUTING: "Executing",
    FINISHED: "Finished",
    CANCELLED: "Cancelled",
    ERROR: "Error",
});

export const PIPELINE_INSTANCE_STATUS = Object.freeze({
    PLANNED: "Planned",
    ONGOING: "Ongoing",
    SUSPENDED: "Suspended",
    ERROR: "Error",
    FINISHED: "Finished",
});

export const REJECTION_REASON = Object.freeze({
    BAD_RESULTS: "BadResults",
    BAD_PARAMETERS: "BadParameters",
    PROCESS_ERROR: "ProcessError",
    PROCESS_TIMEOUT: "ProcessTimeout",
    OTHERS: "Others",
});

export const MISSION_PRE_PROCESSING_STATUS = Object.freeze({
    REPORT_TO_VALIDATE: "ReportToValidate",
    REPORT_VALIDATED: "ReportValidated",
    REPORT_REJECTED: "ReportRejected",
    WAITING_FOR_REPORT_FEEDBACK: "WaitingForReportFeedback",
    REPORT_FEEDBACK_VALIDATED: "ReportFeedbackValidated",
    REPORT_FEEDBACK_REJECTED: "ReportFeedbackRejected",
    CANNOT_CREATE_ORTHOIMAGE: "CannotCreateOrthoimage",
    WAITING_FOR_PLOTMAP: "WaitingForPlotMap",
    COMPLETED: "Completed",
});

export const MISSION_STATUS = Object.freeze({
    ON_HOLD: "On Hold",
    PROCESSING: "Processing",
    DELIVERED: "Delivered",
    ABORTED: "Aborted",
});

export const MISSION_SUB_STATUS = Object.freeze({
    CLIENT_ACTION_REQUIRED: "Client action required",
    PLOT_MAP_GENERATION: "Plot map generation",
    IN_THE_QUEUE: "In the queue",
    ONGOING: "Ongoing",
    DELIVERED: "Delivered",
    AT_CLIENT_REQUEST: "At client request",
    DATA_QUALITY_ISSUE: "Data quality issue",
    DATA_NOT_PROVIDED: "Data not provided",
});

export const EXPERIMENT_MISSION_STATUS = Object.freeze({
    WAITING_FOR_RAW_DATA: "WaitingForRawData",
    RAW_DATA_RECEIVED: "RawDataReceived",
    RAW_DATA_INCOMPLETED: "RawDataIncompleted",
    TO_PROCESS: "ToProcess",
    PROCESS_ONGOING: "ProcessOngoing",
    PROCESS_ERROR: "ProcessError",
    PROCESS_ABORTED: "ProcessAborted",
    PROCESS_FINISHED: "ProcessFinished",
    UPLOADED: "Uploaded",
});

export const TRAIT_GROUP_ASSOCIABLE_TYPES = Object.freeze({
    MISSION: "Mission",
    EXPERIMENT_MISSION: "ExperimentMission",
});

export const SYSTEMS = Object.freeze({
    UAV: "UAV",
    PHENOMOBILE: "Phenomobile",
    SATELLITE: "Satellite",
    LITERAL: "Literal",
    CROPSCAN: "CropScan",
});

export const DATATYPES_ENUM = Object.freeze({
    RGB: "RGB",
    MULTISPECTRAL: "Multispectral",
    LIDAR: "Lidar",
    THERMAL: "Thermal",
});

export const USER_TYPES = Object.freeze({
    SUPER_ADMIN: "SUPER_ADMIN",
    ADMIN: "ADMIN",
    USER: "USER",
});

export const TRAIT_TYPE = Object.freeze({
    BIOCHEMICAL: "Biochemical",
    BIOPHYSICAL: "Biophysical",
    LIGHT_AND_RADIATION: "Light and radiation",
});

export const REFERENCE_OBJECT = Object.freeze({
    LEAF_WIDTH: "leaf_width",
    PLANT_SIZE: "plant_size",
    ORGAN_WIDTH: "organ_width",
});

export const PLOT_MAP_PROVIDER = Object.freeze({
    HIPHEN: {
        value: "Hiphen",
        label: "Provided by Hiphen",
    },
    CLIENT: {
        value: "Client",
        label: "Provided by the client",
    },
    NOT_REQUIRED: {
        value: "NotRequired",
        label: "Not required",
    },
});

export const DEADLINE_TYPE = Object.freeze({
    REAL_TIME: {
        value: "RealTime",
        label: "In real time",
    },
    DAY_5: {
        value: "Day5",
        label: "Within 5 days",
    },
    DAY_30_AFTER_EACH: {
        value: "Day30AfterEach",
        label: "Within 30 days after each acquisition",
    },
    DAY_30_AFTER_LAST: {
        value: "Day30AfterLast",
        label: "Within 30 days after the last acquisition",
    },
    NO_DEADLINE: {
        value: "NoDeadline",
        label: "No deadline",
    },
});

export const TRAIT_GROUP_CATEGORY = Object.freeze({
    CANOPY_DEVELOPMENT: "Canopy Development",
    BIOMASS_PROXY: "Biomass Proxy",
    TRIAL_QUALITY: "Trial Quality",
    HARVEST_INDEX_AND_QUALITY: "Harvest Index and Quality",
    PLANT_STRESS: "Plant Stress",
});

export const RAW_DATASET_TYPES = Object.freeze({
    ACQUISITION_PICTURE: "AcquisitionPicture",
    OVERLAP_GRAPH: "OverlapGraph",
});

/**
 * Cloverfield constants
 */
export const CF_CONTRACT_SYSTEMS = Object.freeze({
    AV: {
        value: "AV",
        label: "Aircraft System",
    },
    SATELLITE: {
        value: "satellite",
        label: "Satellite",
    },
    PHENOMOBILE: {
        value: "phenomobile",
        label: "Phenomobile",
    },
    LITERAL: {
        value: "literal",
        label: "Literal",
    },
});

/**
 * Validation messages
 */
export const INPUT_VALIDATION = Object.freeze({
    REQUIRED: "This field is required.",
    REQUIRED_SHORT: "Required.",
    INVALID: "This input is invalid.",
    INVALID_SHORT: "Invalid.",
    EMAIL_INVALID: "Please enter only valid email handles.",
    INVALID_DATE: "Invalid date.",
    MAX_START_DATE_ERROR: "Starting date cannot exceed ending date.",
    MIN_END_DATE_ERROR: "Ending date cannot precede starting date.",
    ZERO_DATE_COUNT_ERROR: "Date count cannot be 0.",
    POSITIVE: "Must be positive.",
    NON_NEGATIVE: "Must not be negative.",
    LESS_THAN_A_HUNDRED: "Must be less than 100.",
    DEGREE_INVALID: "Must be between 0 and 360.",
    LATITUDE_INVALID: "Must be between -90 and 90.",
    LONGITUDE_INVALID: "Must be between -180 and 180.",
    MISSING_DATA:
        "The form is missing either CSV data, the field map, or both.",
    DUPLICATE_UNIQUE_FIELDS:
        "Some unique properties are duplicated. Make sure plot_id and the X & Y pairs are unique for each plot.",
});

/**
 * Input validation regular expressions
 */
// Email handle (the part after @) regex
export const EMAIL_HANDLE_REGEX =
    // eslint-disable-next-line no-control-regex
    /^(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

// Flight sequence regex
// Accepts an integer or an integer range. For example, "2" or "4-7".
export const FLIGHT_SEQUENCE_REGEX = /^\d+(-\d+)?$/;

/**
 * External websites
 */
export const HIPHEN_CONTACT_US_PAGE = "https://www.hiphen-plant.com/contact/";
export const CLOVERFIELD_MAP = `${process.env.REACT_APP_CLOVERFIELD_URL}`
    ? `${process.env.REACT_APP_CLOVERFIELD_URL}/map`
    : undefined;

/**
 * Margin of error / warning for mission protocol GSD
 */
export const ALLOWED_FACTOR_BELOW_MINIMUM_GSD = 1.1;

/**
 * Thresholds for flags
 */
export const FLAGS_THRESHOLDS = Object.freeze({
    ALTITUDE_VARIATION: 2,
    SATURATION: 0.5,
    EXPOSURE_TIME: 4,
    BLUR_SCORE: 0.33,
    BLUR_PERCENTAGE: 5,
});

/**
 * Strings for flags
 */
export const FLAGS_STRINGS = Object.freeze({
    MISSING_RAWDATASET: {
        value: "RawDatasetsMissing",
        message: "Missing raw images",
        icon: ImageNotSupportedIcon,
    },
    GSD_NOT_MET: {
        value: "GSDRequirementNotMet",
        message: "GSD requirement not met",
        icon: LensBlurIcon,
    },
    SATURATION: {
        value: "SaturationLimitExceeded",
        message: "Saturation limit exceeded",
        icon: WbSunnyIcon,
    },
    EXPOSURE_TIME: {
        value: "ExposureTimeLimitExceeded",
        message: "Exposure time limit exceeded",
        icon: HourglassFullIcon,
    },
    ACQUISITION_VECTOR_DIFFERENT: {
        value: "AcquisitionVectorDifferent",
        message: "Acquisition vector is not the same",
        icon: CameraAltIcon,
    },
    FOCAL_LENGTH_DIFFERENT: {
        value: "FocalLengthDifferent",
        message: "Focal Length is not the same",
        icon: FilterCenterFocusIcon,
    },
    CROP_DIFFERENT: {
        value: "CropDifferent",
        message: "Crop is different",
        icon: GrassIcon,
    },
    NULL_METADATA: {
        value: "NullMetadata",
        message: "Some images have null values in their metadata",
        icon: CodeOffIcon,
    },
});

/**
 * Temporary constant to filter supervisors from regular users
 * This will eventually disappear whenever users are revamped
 * All the names here are managing at least one campaign on HCC-dev
 */
export const CAMPAIGN_MANAGER_NAMES = Object.freeze({
    KAMRAN_IRFAN: "kamran irfan",
    MARC_LABADIE: "marc labadie",
    ADRIEN_VIELIX: "adrien vielix",
    ALEXANDRA_BURGY: "alexandra bürgy",
    MIARKA_SINKORA: "miarka sinkora",
    PATRIZIA_ZAMBERLETTI: "patrizia zamberletti",
    ALISSON_POILANE: "alisson poilane",
    BAPTISTE_HUSTACHE: "baptiste hustache",
    LEA_CABROL: "léa cabrol",
});
// TODO: Take into account the window width
export const CUSTOM_DRAWER_WIDTH = Object.freeze({
    DEFAULT: 240,
    MIN: 240,
    MAX: 1000,
});

export const CONTRACT_STATUS = Object.freeze({
    PRE_ORDERED: "pre-ordered",
    ORDERED: "ordered",
    FINISHED: "finished",
    ARCHIVED: "archived",
});

export const REPORT_STATUS = Object.freeze({
    VALIDATED: "Validated",
    WARNING: "Warning",
    UNKNOWN: "Unknown",
    REJECTED: "Rejected",
});

export const GEOMAN_CUSTOM_TOGGLE_BUTTON_NAMES = Object.freeze({
    SINGLE_TARGET_MODE: "singleTargetMode",
    SHIFT_MODE: "shiftMode",
    LOCKED_SHAPE_MODE: "lockedShapeMode",
});

export const PIPELINE_TEMPLATE_LITERAL_PRESETS_TGDTS = Object.freeze({
    GENERIC: {
        tgdtArray: [
            "7c3aa543-234e-499a-9f5b-983657b56052", // Green Cover ; RGB
            "2b8589df-f8a7-4502-a45a-c6d0b52e054f", // Vegetation Indices ; MS
        ],
        label: "Generic",
    },
    WHEAT: {
        tgdtArray: [
            "7c3aa543-234e-499a-9f5b-983657b56052", // Green Cover ; RGB
            "0f573b0a-0901-474b-8ffb-bc55143fdd19", // Plant Cover ; RGB
            "b6fc1977-548f-456a-bd04-4b6469310752", // Organs Count ; RGB
            "51bec518-bd91-47da-bf1a-f8a250091907", // Height & Biovolume ; RGB
            "2b8589df-f8a7-4502-a45a-c6d0b52e054f", // Vegetation Indices ; MS
            "ba998145-625a-4bac-85f9-2dcd8b21c4ed", // LAI, FiPAR & Chlorophyll Simulations ; MS
        ],
        label: "Wheat",
    },
});

export const TRANSFER_LIST_ORIENTATION_THRESHOLD = 400;

// zIndex used for buttons that are overlayed on top of a leaflet map with tile layers, as tile layers default zIndex seems to be 400
export const LEAFLET_OVERLAYED_BUTTONS_ZINDEX = 425;

/**
 * MUI component sizes
 * The following is a list of found MUI component sizes.
 * It is not for controlling the size of MUI components but for
 * other elements to know what size to expect of an MUI component.
 */
export const MUI_DEFAULT_SIZES = Object.freeze({
    BUTTON: {
        MEDIUM: 36,
    },
});

// AUTH
export const ACCESS_ROLE = "web-access";
export const RESOURCE_ACCESS = "hcc-fe";

export const BE_RESOURCE_ACCESS = "hcc-be";
export const KC_ROLES = Object.freeze({
    ADMIN: "admin",
    BACKOFFICE: "backoffice",
    BASIC: "basic",
});
