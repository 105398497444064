import { DYNAMIC_FEATURE_ID_FIELD_NAME } from "./utils/constants";
import { INPUT_VALIDATION } from "../../constants";
import L from "leaflet";
import { checkIfDuplicateExists } from "../../utils/checkIfDuplicateExists";
import { epsgConverter } from "./utils/epsgConverter";
import { getDistanceFromPoints } from "./utils/getDistanceFromPoints";
import { getSelectableMetadata } from "./utils/getSelectableMetadata";
import proj4 from "proj4";
import { replaceMapDataGeoJson } from "./utils/fileIoUtils";
import slugify from "slugify";

export function parcellaireCreationSubmitImport(
    payload,
    setIsOpen,
    parcellaireDispatch,
    mapRef,
    setGeoJsonKey,
    openSnackbar,
    isLiteral = false,
    site,
    setSelectableMetadata
) {
    if (!payload.csvData || (!isLiteral && !payload.blueRectangle)) {
        openSnackbar(INPUT_VALIDATION.MISSING_DATA, "error");
    } else {
        if (!payload.xSpacing) payload.xSpacing = 0;
        if (!payload.ySpacing) payload.ySpacing = 0;

        const createdParcellaire = {
            type: "FeatureCollection",
            features: [],
        };

        const positionTable = [];

        const csvHeaders = payload.csvData[0].map((header) =>
            header.toLowerCase().trim()
        );

        const slugifiedSiteName = site ? slugify(site.name, "_") : null;

        const literalEPSG =
            isLiteral && epsgConverter(payload.latitude, payload.longitude);

        if (literalEPSG)
            proj4.defs(literalEPSG.epsgCode, literalEPSG.epsgString);

        const defaultLiteralCoordMeter = isLiteral
            ? proj4("WGS84", literalEPSG.epsgCode, [
                  parseFloat(payload.longitude),
                  parseFloat(payload.latitude),
              ])
            : null;

        const unicityCheck = { name: [], x_y: [] };

        payload.csvData.slice(1).forEach((line) => {
            if (line.length > 1) {
                // making sure line isn't empty data (or last line of the csv)
                const metadata = {};
                line.forEach((cell, index) => {
                    metadata[csvHeaders[index]] = cell.trim();
                });
                if (!metadata.experiment) {
                    metadata.experiment = isLiteral
                        ? slugifiedSiteName ?? "default"
                        : payload.blockName
                          ? slugify(payload.blockName, "_")
                          : "default";
                }

                if (isLiteral) {
                    metadata.name =
                        metadata.plot_id ??
                        `${slugifiedSiteName}_X${metadata.x}_Y${metadata.y}`;
                    delete metadata.plot_id;
                    if (metadata.name !== "buffer" && metadata.name !== "b")
                        unicityCheck.name.push(metadata.name);
                } else {
                    metadata.client_id = metadata.plot_id;
                    metadata.plot_id = `${slugify(metadata.experiment, "_")}_X${metadata.x}_Y${metadata.y}`;
                    unicityCheck.name.push(metadata.client_id);
                }
                unicityCheck.x_y.push(`${metadata.x}_${metadata.y}`);

                positionTable.push(metadata);
            }
        });

        if (positionTable.length) {
            if (payload.sizeScaling || isLiteral) {
                const [trueXSize, trueYSize] = payload.reverseSizes
                    ? [payload.ySize, payload.xSize]
                    : [payload.xSize, payload.ySize];

                const [xCenter, yCenter] =
                    defaultLiteralCoordMeter ??
                    proj4("WGS84", payload.usedEPSG.epsgCode, [
                        parseFloat(payload.o[0]),
                        parseFloat(payload.o[1]),
                    ]); // base longitude and latitude transformed in meters

                const trueXAngle = isLiteral
                    ? (parseFloat(payload.angle) / 180) * Math.PI
                    : (payload.xAngle / 180) * Math.PI;
                const trueYAngle = isLiteral
                    ? ((parseFloat(payload.angle) + 90) / 180) * Math.PI
                    : (payload.yAngle / 180) * Math.PI;
                const xDirection = [Math.cos(trueXAngle), Math.sin(trueXAngle)];
                const yDirection = [Math.cos(trueYAngle), Math.sin(trueYAngle)];

                const meters_to_WGS84 = (x, y) => {
                    return proj4(
                        isLiteral
                            ? literalEPSG.epsgCode
                            : payload.usedEPSG.epsgCode,
                        "WGS84",
                        [
                            xCenter + x * xDirection[0] + y * yDirection[0],
                            yCenter + x * xDirection[1] + y * yDirection[1],
                        ]
                    );
                };

                positionTable.forEach((position, index) => {
                    if (
                        (!isLiteral &&
                            position.client_id.toLowerCase().trim() !==
                                "buffer" &&
                            position.client_id.toLowerCase().trim() !== "b") ||
                        (isLiteral &&
                            position.name.toLowerCase().trim() !== "buffer" &&
                            position.name.toLowerCase().trim() !== "b")
                    ) {
                        const [x1, x2, y1, y2] = [
                            (position.x - 1) * trueXSize +
                                (position.x - 1) * payload.xSpacing,
                            (position.x - 1) * trueXSize +
                                trueXSize +
                                (position.x - 1) * payload.xSpacing,
                            (position.y - 1) * trueYSize +
                                (position.y - 1) * payload.ySpacing,
                            (position.y - 1) * trueYSize +
                                trueYSize +
                                (position.y - 1) * payload.ySpacing,
                        ];
                        const firstPointCoord = meters_to_WGS84(x1, y1); // this is defined in a const because it's used twice and we don't want to calculate it twice

                        createdParcellaire.features.push({
                            type: "Feature",
                            properties: {
                                [DYNAMIC_FEATURE_ID_FIELD_NAME]: index,
                                ...position,
                            },
                            geometry: {
                                type: "Polygon",
                                coordinates: [
                                    [
                                        firstPointCoord,
                                        meters_to_WGS84(x2, y1),
                                        meters_to_WGS84(x2, y2),
                                        meters_to_WGS84(x1, y2),
                                        firstPointCoord,
                                    ],
                                ],
                            },
                        });
                    }
                });
            } else {
                const [xCenter, yCenter] = proj4(
                    "WGS84",
                    payload.usedEPSG.epsgCode,
                    [parseFloat(payload.o[0]), parseFloat(payload.o[1])]
                ); // base longitude and latitude transformed in meters

                const xFullSize = getDistanceFromPoints(payload.o, payload.x);
                const yFullSize = getDistanceFromPoints(payload.o, payload.y);

                let [xMin, xMax, yMin, yMax] = [
                    positionTable[0].x,
                    positionTable[0].x,
                    positionTable[0].y,
                    positionTable[0].y,
                ];
                positionTable.slice(1).forEach((pos) => {
                    xMin = Number(pos.x) < xMin ? Number(pos.x) : xMin;
                    xMax = Number(pos.x) > xMax ? Number(pos.x) : xMax;
                    yMin = Number(pos.y) < yMin ? Number(pos.y) : yMin;
                    yMax = Number(pos.y) > yMax ? Number(pos.y) : yMax;
                });

                const [trueXSize, trueYSize] = payload.reverseSizes
                    ? [
                          (yFullSize - payload.ySpacing * (yMax - yMin)) /
                              (yMax - yMin + 1),
                          (xFullSize - payload.xSpacing * (xMax - xMin)) /
                              (xMax - xMin + 1),
                      ]
                    : [
                          (xFullSize - payload.xSpacing * (xMax - xMin)) /
                              (xMax - xMin + 1),
                          (yFullSize - payload.ySpacing * (yMax - yMin)) /
                              (yMax - yMin + 1),
                      ];

                const trueXAngle = (payload.xAngle / 180) * Math.PI;
                const trueYAngle = (payload.yAngle / 180) * Math.PI;
                const xDirection = [Math.cos(trueXAngle), Math.sin(trueXAngle)];
                const yDirection = [Math.cos(trueYAngle), Math.sin(trueYAngle)];

                const meters_to_WGS84 = (x, y) => {
                    return proj4(payload.usedEPSG.epsgCode, "WGS84", [
                        xCenter + x * xDirection[0] + y * yDirection[0],
                        yCenter + x * xDirection[1] + y * yDirection[1],
                    ]);
                };

                positionTable.forEach((position, index) => {
                    if (position.client_id.toLowerCase().trim() !== "buffer") {
                        const [x1, x2, y1, y2] = [
                            (position.x - 1) * trueXSize +
                                (position.x - 1) * payload.xSpacing,
                            (position.x - 1) * trueXSize +
                                (position.x - 1) * payload.xSpacing +
                                trueXSize,
                            (position.y - 1) * trueYSize +
                                (position.y - 1) * payload.ySpacing,
                            (position.y - 1) * trueYSize +
                                (position.y - 1) * payload.ySpacing +
                                trueYSize,
                        ];
                        const firstPointCoord = meters_to_WGS84(x1, y1); // this is defined in a const because it's used twice and we don't want to calculate it twice
                        createdParcellaire.features.push({
                            type: "Feature",
                            properties: {
                                [DYNAMIC_FEATURE_ID_FIELD_NAME]: index,
                                ...position,
                            },
                            geometry: {
                                type: "Polygon",
                                coordinates: [
                                    [
                                        firstPointCoord,
                                        meters_to_WGS84(x2, y1),
                                        meters_to_WGS84(x2, y2),
                                        meters_to_WGS84(x1, y2),
                                        firstPointCoord,
                                    ],
                                ],
                            },
                        });
                    }
                });
            }
        }

        if (
            checkIfDuplicateExists(unicityCheck.name) ||
            checkIfDuplicateExists(unicityCheck.x_y)
        ) {
            openSnackbar(INPUT_VALIDATION.DUPLICATE_UNIQUE_FIELDS, "error");
        } else {
            replaceMapDataGeoJson(
                parcellaireDispatch,
                createdParcellaire,
                mapRef
            );

            // removing coord marker
            mapRef.current.pm
                .getGeomanLayers()
                .filter((layer) => layer instanceof L.Marker)
                .forEach((layer) => {
                    layer.remove();
                });

            if (setIsOpen) setIsOpen(false);

            setSelectableMetadata(
                getSelectableMetadata(
                    isLiteral
                        ? csvHeaders
                              .filter((header) => header !== "name")
                              .map((header) =>
                                  header === "plot_id" ? "name" : header
                              )
                        : csvHeaders,
                    true
                )
            );

            setGeoJsonKey(() => {
                return new Date().getTime();
            });
        }
    }
}
