import AgricultureOutlinedIcon from "@mui/icons-material/AgricultureOutlined";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { FRONTEND_ROUTES } from "../../frontendRoutes";
import GrassIcon from "@mui/icons-material/Grass";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { KC_ROLES } from "../../constants";
import MapIcon from "@mui/icons-material/Map";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import RouteIcon from "@mui/icons-material/Route";
import RuleIcon from "@mui/icons-material/Rule";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import SyncIcon from "@mui/icons-material/Sync";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import TrafficOutlinedIcon from "@mui/icons-material/TrafficOutlined";
import { useKcRole } from "../../hooks/useKcRole";

const iconSize = { width: 22, height: 22 };
const iconSizeSmall = { width: 20, height: 20 };

const companies = {
    title: "companies",
    path: `/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.COMPANY}`,
    icon: <BusinessIcon sx={iconSizeSmall} />,
    soon: false,
};

const campaigns = {
    title: "campaigns",
    path: `/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT}`,
    icon: <ArticleOutlinedIcon sx={iconSizeSmall} />,
    soon: false,
};

const users = {
    title: "users",
    icon: <GroupOutlinedIcon sx={iconSizeSmall} />,
    soon: true,
};

const teams = {
    title: "teams",
    icon: <GroupsOutlinedIcon sx={iconSizeSmall} />,
    soon: true,
};

const monitoring = {
    title: "monitoring",
    icon: <MonitorHeartOutlinedIcon sx={iconSize} />,
    path: `/${FRONTEND_ROUTES.MONITORING}`,
    soon: false,
    children: [
        {
            title: "bookings",
            path: `/${FRONTEND_ROUTES.MONITORING}/${FRONTEND_ROUTES.BOOKINGS}`,
            icon: <CalendarMonthOutlinedIcon sx={iconSizeSmall} />,
            soon: false,
        },
        {
            title: "routing",
            path: `/${FRONTEND_ROUTES.MONITORING}/${FRONTEND_ROUTES.ROUTING}`,
            icon: <RouteIcon sx={iconSizeSmall} />,
            soon: false,
        },
        {
            title: "manifest",
            icon: <ReceiptLongOutlinedIcon sx={iconSizeSmall} />,
            soon: true,
        },
    ],
};

const traffic = {
    title: "traffic",
    icon: <TrafficOutlinedIcon sx={iconSize} />,
    path: `/${FRONTEND_ROUTES.TRAFFIC}`,
    soon: false,
    children: [
        {
            title: "inbound",
            path: `/${FRONTEND_ROUTES.TRAFFIC}/${FRONTEND_ROUTES.INBOUND_TRAFFIC}`,
            icon: <ArrowDownwardIcon sx={iconSizeSmall} />,
            soon: false,
        },
        {
            title: "processing",
            path: `/${FRONTEND_ROUTES.TRAFFIC}/${FRONTEND_ROUTES.PROCESSING_TRAFFIC}`,
            icon: <SyncIcon sx={iconSizeSmall} />,
            soon: false,
        },
        {
            title: "completed",
            path: `/${FRONTEND_ROUTES.TRAFFIC}/${FRONTEND_ROUTES.COMPLETED_TRAFFIC}`,
            icon: <RuleIcon sx={iconSizeSmall} />,
            soon: false,
        },
    ],
};

const validation = {
    title: "validation",
    icon: <TaskOutlinedIcon sx={iconSize} />,
    soon: false,
    path: `/${FRONTEND_ROUTES.VALIDATION}`,
    /*children: [
    {
        title: "plot map",
        icon: <MapIcon sx={iconSize} />,
        path: `/${FRONTEND_ROUTES.PARCELLAIRE}`,
        soon: false,
    },
],*/
};

const alerts = {
    title: "alerts",
    icon: <NotificationsOutlinedIcon sx={iconSize} />,
    soon: true,
};

const academy = {
    title: "academy",
    icon: <SchoolOutlinedIcon sx={iconSize} />,
    path: "https://www.hiphen-plant.com/academy/",
    soon: false,
};

const glossaries = {
    title: "glossaries",
    icon: <MenuBookIcon sx={iconSize} />,
    path: `/${FRONTEND_ROUTES.GLOSSARY}`,
    soon: false,
    children: [
        {
            title: "traits",
            path: `/${FRONTEND_ROUTES.GLOSSARY}/${FRONTEND_ROUTES.TRAIT}`,
            icon: <AssessmentOutlinedIcon sx={iconSizeSmall} />,
            soon: false,
        },
        {
            title: "sensors",
            path: `/${FRONTEND_ROUTES.GLOSSARY}/${FRONTEND_ROUTES.SENSOR}`,
            icon: <CameraAltOutlinedIcon sx={iconSizeSmall} />,
            soon: false,
        },
        {
            title: "system models",
            path: `/${FRONTEND_ROUTES.GLOSSARY}/${FRONTEND_ROUTES.SYSTEM_MODEL}`,
            icon: <AgricultureOutlinedIcon sx={iconSizeSmall} />,
            soon: false,
        },
        {
            title: "crops",
            path: `/${FRONTEND_ROUTES.GLOSSARY}/${FRONTEND_ROUTES.CROP}`,
            icon: <GrassIcon sx={iconSizeSmall} />,
            soon: false,
        },
    ],
};

const parcellaire = {
    title: "plot map",
    icon: <MapIcon sx={iconSize} />,
    path: `/${FRONTEND_ROUTES.PARCELLAIRE}`,
    soon: false,
};

export function useSidebarConfig() {
    const { kcRole } = useKcRole();

    return kcRole === KC_ROLES.BASIC
        ? [companies, campaigns]
        : [
              companies,
              campaigns,
              users,
              teams,
              monitoring,
              traffic,
              validation,
              alerts,
              academy,
              glossaries,
              parcellaire,
          ];
}
