import {
    Button,
    Divider,
    Grid,
    MenuItem,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { CF_CONTRACT_SYSTEMS, CONTRACT_STATUS } from "../../constants";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useFetch, useSnackbar } from "../../hooks";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { ContractFormSkeleton } from "./ContractFormSkeleton";
import { DatePicker } from "@mui/x-date-pickers";
import { FRONTEND_ROUTES } from "../../frontendRoutes";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { INPUT_VALIDATION } from "../../constants";
import { LabelStyle } from "../../components/LabelStyle";
import { LoadingButton } from "@mui/lab";
import { PAGINATION } from "../../constants";
import { formatDateISO } from "../../utils/formatTime";
import { parseISO } from "date-fns";
import { useKcRole } from "../../hooks/useKcRole";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { useState } from "react";

export const ContractForm = ({ contract, mutate }) => {
    const { isBasicRole } = useKcRole();

    const [{ startDateError, endDateError, reportDateError }, setDateError] =
        useState({});
    const { openSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { data: companies, error: companiesFetchError } = useSWR(
        !contract
            ? `${BACKEND_ROUTES.COMPANY}?sort=name&limit=${PAGINATION.COMPANY.LIMIT.MAX}`
            : null
    );
    const { data: users, error: usersFetchError } = useSWR(
        isBasicRole ? null : `${BACKEND_ROUTES.USER}?sort=firstName`
    );
    const { data: uploadTemplates, error: uploadTemplatesFetchError } =
        useSWRImmutable(
            !contract ? `${BACKEND_ROUTES.CF_UPLOAD_TEMPLATES}` : null
        );

    const {
        control,
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting, isDirty },
    } = useForm({
        defaultValues: contract
            ? {
                  name: contract.name,
                  companyUuid: contract.companyUuid,
                  startDate: parseISO(contract.startDate),
                  endDate: parseISO(contract.endDate),
                  supervisorUuid: contract.supervisorUuid,
                  salespersonUuid: contract.salespersonUuid,
                  reportDate: contract.reportDate
                      ? parseISO(contract.reportDate)
                      : null,
                  status: contract.status,
              }
            : {
                  name: "",
                  companyUuid: "",
                  startDate: null,
                  endDate: null,
                  supervisorUuid: "",
                  salespersonUuid: "",
                  reportDate: null,
                  status: CONTRACT_STATUS.PRE_ORDERED,
                  system: "",
                  uploadTemplateId: "",
                  hasExperiment: false,
              },
    });

    const [startDateWatch, endDateWatch] = useWatch({
        name: ["startDate", "endDate"],
        control,
    });

    const { post, patch } = useFetch();

    const mergedFetchError =
        companiesFetchError ?? usersFetchError ?? uploadTemplatesFetchError;

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;
    if (
        (!companies && !contract) ||
        (!users && !isBasicRole) ||
        (!uploadTemplates && !contract)
    )
        return <ContractFormSkeleton />;

    const onSubmit = async ({ companyUuid, uploadTemplateId, ...payload }) => {
        const body = {
            ...payload,
            startDate: formatDateISO(payload.startDate),
            endDate: formatDateISO(payload.endDate),
            reportDate: payload.reportDate && formatDateISO(payload.reportDate),
            uploadTemplateId: uploadTemplateId || undefined,
        };

        body.companyUuid = undefined;
        if (body.uploadTemplateId === "") body.uploadTemplateId = undefined;

        const updatedContract = contract
            ? await patch(`${BACKEND_ROUTES.CONTRACT}/${contract.uuid}`, {
                  body,
              })
            : await post(`${BACKEND_ROUTES.COMPANY}/${companyUuid}/contracts`, {
                  body,
              });

        if (updatedContract) {
            openSnackbar(
                `Campaign ${updatedContract.name} ${
                    contract ? "updated" : "created"
                } successfully.`,
                "success"
            );

            contract
                ? mutate()
                : navigate(
                      `/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT}`
                  );
        }
    };

    const onCancel = () => {
        contract
            ? reset()
            : navigate(
                  `/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT}`
              );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        {contract
                            ? "Campaign management"
                            : "Create a new campaign"}
                    </Typography>
                </Grid>
                <Grid item xs={2}>
                    <LabelStyle>Company{contract ? "" : " *"}</LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        control={control}
                        name="companyUuid"
                        render={({ field: { ref, ...fieldProps } }) => (
                            <TextField
                                {...fieldProps}
                                fullWidth
                                id="companyUuid"
                                disabled={Boolean(contract || isBasicRole)}
                                select
                                inputRef={ref}
                                error={Boolean(errors.companyUuid)}
                                helperText={errors.companyUuid?.message}
                            >
                                {companies ? (
                                    companies.rows.map((company) => (
                                        <MenuItem
                                            key={company.uuid}
                                            value={company.uuid}
                                        >
                                            {company.name}
                                        </MenuItem>
                                    ))
                                ) : contract ? (
                                    <MenuItem
                                        key={contract.companyUuid}
                                        value={contract.companyUuid}
                                    >
                                        {contract.Company?.name}
                                    </MenuItem>
                                ) : null}
                            </TextField>
                        )}
                        rules={{
                            required: INPUT_VALIDATION.REQUIRED,
                        }}
                    />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={2}>
                    <LabelStyle>Name{contract ? "" : " *"}</LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        name="name"
                        id="name"
                        fullWidth
                        disabled={isBasicRole}
                        type="text"
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                        {...register("name", {
                            required: INPUT_VALIDATION.REQUIRED,
                        })}
                    />
                </Grid>
                <Grid item xs={2}>
                    <LabelStyle>Starting Date{contract ? "" : " *"}</LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        name="startDate"
                        control={control}
                        render={({ field: { ref, ...fieldProps } }) => (
                            <DatePicker
                                {...fieldProps}
                                slotProps={{
                                    textField:
                                        !startDateWatch &&
                                        errors.startDate?.type === "required"
                                            ? {
                                                  helperText:
                                                      errors.startDate?.message,
                                                  error: true,
                                              }
                                            : {
                                                  helperText: startDateError,
                                                  // do not define "error" here because it would override the default "error"
                                              },
                                }}
                                inputRef={ref}
                                id="startDate"
                                disabled={Boolean(contract || isBasicRole)}
                                onError={(reason, value) => {
                                    switch (reason) {
                                        case "invalidDate":
                                            setDateError((prevState) => ({
                                                ...prevState,
                                                startDateError:
                                                    INPUT_VALIDATION.INVALID_DATE,
                                            }));
                                            break;

                                        case "maxDate":
                                            setDateError((prevState) => ({
                                                ...prevState,
                                                startDateError:
                                                    INPUT_VALIDATION.MAX_START_DATE_ERROR,
                                            }));
                                            break;

                                        default:
                                            setDateError((prevState) => ({
                                                ...prevState,
                                                startDateError: undefined,
                                            }));
                                    }
                                }}
                                maxDate={endDateWatch ?? undefined}
                                format="yyyy-MM-dd"
                                mask={"____-__-__"}
                            />
                        )}
                        rules={{
                            required: INPUT_VALIDATION.REQUIRED,
                            validate: () => !startDateError,
                        }}
                    />
                </Grid>

                {!isBasicRole ? (
                    <>
                        <Grid item xs={2}>
                            <LabelStyle>
                                Campaign Manager{contract ? "" : " *"}
                            </LabelStyle>
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                control={control}
                                name="supervisorUuid"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        fullWidth
                                        id="supervisorUuid"
                                        select
                                        inputRef={ref}
                                        error={Boolean(errors.supervisorUuid)}
                                        helperText={
                                            errors.supervisorUuid?.message
                                        }
                                    >
                                        {users ? (
                                            users.map((user) => (
                                                <MenuItem
                                                    key={user.uuid}
                                                    value={user.uuid}
                                                >
                                                    {user.firstName}{" "}
                                                    {user.lastName}
                                                </MenuItem>
                                            ))
                                        ) : contract ? (
                                            <MenuItem
                                                key={contract.supervisorUuid}
                                                value={contract.supervisorUuid}
                                            >
                                                {contract.Supervisor?.firstName}{" "}
                                                {contract.Supervisor?.lastName}
                                            </MenuItem>
                                        ) : null}
                                    </TextField>
                                )}
                                rules={{
                                    required: INPUT_VALIDATION.REQUIRED,
                                }}
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={2}>
                            <LabelStyle>
                                Project Lead{contract ? "" : " *"}
                            </LabelStyle>
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                control={control}
                                name="salespersonUuid"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        fullWidth
                                        id="salespersonUuid"
                                        disabled
                                        select
                                        inputRef={ref}
                                        error={Boolean(errors.salespersonUuid)}
                                        helperText={
                                            errors.salespersonUuid?.message
                                        }
                                    >
                                        {users ? (
                                            users.map((user) => (
                                                <MenuItem
                                                    key={user.uuid}
                                                    value={user.uuid}
                                                >
                                                    {user.firstName}{" "}
                                                    {user.lastName}
                                                </MenuItem>
                                            ))
                                        ) : contract ? (
                                            <MenuItem
                                                key={contract.salespersonUuid}
                                                value={contract.salespersonUuid}
                                            >
                                                {
                                                    contract.Salesperson
                                                        ?.firstName
                                                }{" "}
                                                {contract.Salesperson?.lastName}
                                            </MenuItem>
                                        ) : null}
                                    </TextField>
                                )}
                                rules={{
                                    required: INPUT_VALIDATION.REQUIRED,
                                }}
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={2}>
                    <LabelStyle>Ending Date{contract ? "" : " *"}</LabelStyle>
                </Grid>
                <Grid item xs={4}>
                    <Controller
                        name="endDate"
                        control={control}
                        render={({ field: { ref, ...fieldProps } }) => (
                            <DatePicker
                                {...fieldProps}
                                slotProps={{
                                    textField:
                                        !endDateWatch &&
                                        errors.endDate?.type === "required"
                                            ? {
                                                  helperText:
                                                      errors.endDate?.message,
                                                  error: true,
                                              }
                                            : {
                                                  helperText: endDateError,
                                                  // do not define "error" here because it would override the default "error"
                                              },
                                }}
                                format="yyyy-MM-dd"
                                mask={"____-__-__"}
                                inputRef={ref}
                                id="endDate"
                                disabled={Boolean(contract || isBasicRole)}
                                onError={(reason, value) => {
                                    switch (reason) {
                                        case "invalidDate":
                                            setDateError((prevState) => ({
                                                ...prevState,
                                                endDateError:
                                                    INPUT_VALIDATION.INVALID_DATE,
                                            }));
                                            break;

                                        case "minDate":
                                            setDateError((prevState) => ({
                                                ...prevState,
                                                endDateError:
                                                    INPUT_VALIDATION.MIN_END_DATE_ERROR,
                                            }));
                                            break;

                                        default:
                                            setDateError((prevState) => ({
                                                ...prevState,
                                                endDateError: undefined,
                                            }));
                                    }
                                }}
                                minDate={startDateWatch ?? undefined}
                            />
                        )}
                        rules={{
                            required: INPUT_VALIDATION.REQUIRED,
                            validate: () => !endDateError,
                        }}
                    />
                </Grid>

                {!isBasicRole && (
                    <>
                        <Grid item xs={2}>
                            <LabelStyle>
                                Project Lead{contract ? "" : " *"}
                            </LabelStyle>
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                control={control}
                                name="salespersonUuid"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        fullWidth
                                        id="salespersonUuid"
                                        select
                                        inputRef={ref}
                                        error={Boolean(errors.salespersonUuid)}
                                        helperText={
                                            errors.salespersonUuid?.message
                                        }
                                    >
                                        {users ? (
                                            users.map((user) => (
                                                <MenuItem
                                                    key={user.uuid}
                                                    value={user.uuid}
                                                >
                                                    {user.firstName}{" "}
                                                    {user.lastName}
                                                </MenuItem>
                                            ))
                                        ) : contract ? (
                                            <MenuItem
                                                key={contract.salespersonUuid}
                                                value={contract.salespersonUuid}
                                            >
                                                {
                                                    contract.Salesperson
                                                        ?.firstName
                                                }{" "}
                                                {contract.Salesperson?.lastName}
                                            </MenuItem>
                                        ) : null}
                                    </TextField>
                                )}
                                rules={{
                                    required: INPUT_VALIDATION.REQUIRED,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <LabelStyle>Report Date</LabelStyle>
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                name="reportDate"
                                control={control}
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <DatePicker
                                        {...fieldProps}
                                        slotProps={{
                                            field: { clearable: true },
                                            textField: {
                                                helperText: reportDateError,
                                            },
                                        }}
                                        format="yyyy-MM-dd"
                                        mask={"____-__-__"}
                                        inputRef={ref}
                                        id="reportDate"
                                        onError={(reason, value) => {
                                            switch (reason) {
                                                case "invalidDate":
                                                    setDateError(
                                                        (prevState) => ({
                                                            ...prevState,
                                                            reportDateError:
                                                                INPUT_VALIDATION.INVALID_DATE,
                                                        })
                                                    );
                                                    break;

                                                default:
                                                    setDateError(
                                                        (prevState) => ({
                                                            ...prevState,
                                                            reportDateError:
                                                                undefined,
                                                        })
                                                    );
                                            }
                                        }}
                                    />
                                )}
                                rules={{
                                    validate: () => !reportDateError,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <LabelStyle>
                                Status{contract ? "" : " *"}
                            </LabelStyle>
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                control={control}
                                name="status"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        fullWidth
                                        id="status"
                                        select
                                        inputRef={ref}
                                        error={Boolean(errors.status)}
                                        helperText={errors.status?.message}
                                    >
                                        {Object.values(CONTRACT_STATUS).map(
                                            (status) => (
                                                <MenuItem
                                                    key={status}
                                                    value={status}
                                                >
                                                    {status}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                )}
                                rules={{
                                    required: INPUT_VALIDATION.REQUIRED,
                                }}
                            />
                        </Grid>
                    </>
                )}

                {!contract && (
                    <>
                        <Grid item xs={12}>
                            <Divider
                                sx={{
                                    borderStyle: "dashed",
                                    borderWidth: "thin",
                                }}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                sx={{ color: "text.secondary" }}
                            >
                                Cloverfield fields
                            </Typography>
                        </Grid>

                        <Grid item xs={2}>
                            <LabelStyle>System *</LabelStyle>
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                control={control}
                                name="system"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        fullWidth
                                        id="system"
                                        select
                                        inputRef={ref}
                                        error={Boolean(errors.system)}
                                        helperText={errors.system?.message}
                                    >
                                        {Object.values(CF_CONTRACT_SYSTEMS).map(
                                            (system) => (
                                                <MenuItem
                                                    key={system.value}
                                                    value={system.value}
                                                >
                                                    {system.label}
                                                </MenuItem>
                                            )
                                        )}
                                    </TextField>
                                )}
                                rules={{
                                    required: INPUT_VALIDATION.REQUIRED,
                                }}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <LabelStyle>Upload Template</LabelStyle>
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                control={control}
                                name="uploadTemplateId"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        fullWidth
                                        id="uploadTemplateId"
                                        select
                                        inputRef={ref}
                                        error={Boolean(errors.uploadTemplateId)}
                                        helperText={
                                            errors.uploadTemplateId?.message
                                        }
                                    >
                                        {uploadTemplates.rows
                                            .concat({
                                                id: "",
                                                name: "-- Unset --",
                                            })
                                            .map((template) => (
                                                <MenuItem
                                                    key={template.id}
                                                    value={template.id}
                                                >
                                                    {template.name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                )}
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <LabelStyle>Enable plot map</LabelStyle>
                        </Grid>
                        <Grid item xs={4}>
                            <Controller
                                control={control}
                                name="hasExperiment"
                                render={({
                                    field: { ref, value, ...fieldProps },
                                }) => (
                                    <Switch
                                        {...fieldProps}
                                        inputRef={ref}
                                        checked={value}
                                    />
                                )}
                            />
                        </Grid>
                    </>
                )}

                {(!contract || isDirty) && !isBasicRole && (
                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            spacing={3}
                            justifyContent="flex-end"
                        >
                            <Button type="button" onClick={onCancel}>
                                Cancel
                            </Button>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                {contract ? "Update" : "Create"}
                            </LoadingButton>
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </form>
    );
};
