import { Container } from "@mui/material";
import Page from "../components/Page";
import { Parcellaire } from "../features/parcellaire";

export default function ParcellairePage({ isLiteral }) {
    return (
        <Page title="Plot Map | HCC">
            <Container maxWidth={false} disableGutters>
                <Parcellaire isLiteral={isLiteral} />
            </Container>
        </Page>
    );
}
