/* eslint-disable eqeqeq */
import JSZip from "jszip";
import L from "leaflet";
import download from "downloadjs";
import { geoJSONUtil } from "./geoJSONUtil";
import { getBinaryContent } from "jszip-utils";

// this whole file comes from Arvalis' tool and has been "slightly enhanced"

export function getMapTiles(map, parcellaireState) {
    const bounds = geoJSONUtil.getBoundsOfFeatures(
        parcellaireState.transformedGeoJson.features
    );
    bounds.forEach((coord) => coord.reverse());
    map.flyToBounds(bounds, { maxZoom: 19 }); // has to be animated for moveend

    map.once("moveend", () => {
        const zoom = map.getZoom();
        const usedBounds = map.getBounds();

        map.eachLayer(function (layer) {
            if (layer instanceof L.TileLayer) {
                const urls = getTileUrls(usedBounds, layer, zoom, map);
                const zip = new JSZip();
                let count = 0;
                urls.forEach((url) => {
                    getBinaryContent(url, function (err, data) {
                        if (err) {
                            throw err; // or handle the error
                        }
                        // parse from osm tile url
                        const filename = url.replace(
                            /.*\/(\d+)\/(\d+)\/(\d+).png/,
                            "$1_$2_$3.png"
                        );
                        zip.file(filename, data, { binary: true });

                        count++;
                        // don't know why it's == instead of === but it was like this on Arvalis' tool and tiles didn't work on the literal when I made it ===
                        if (count == urls.length) {
                            zip.generateAsync({ type: "blob" }).then(
                                function (content) {
                                    download(content, "tiles.zip");
                                }
                            );
                        }
                    });
                });
            }
        });
    });
}

function getTileUrls(bounds, tileLayer, zoom, map) {
    const min = map.project(bounds.getNorthWest(), zoom).divideBy(256).floor();
    const max = map.project(bounds.getSouthEast(), zoom).divideBy(256).floor();
    const urls = [];

    for (var i = min.x; i <= max.x; i++) {
        for (var j = min.y; j <= max.y; j++) {
            var coords = new L.Point(i, j);
            coords.z = zoom;
            urls.push(tileLayer.getTileUrl(coords));
        }
    }

    return urls;
}
