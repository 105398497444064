import {
    Autocomplete,
    Card,
    Grid,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMemo, useState } from "react";

import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { BACKEND_ROUTES } from "../../backendRoutes";
import { CONTRACT_STATUS } from "../../constants";
import { ContractTableSkeleton } from "./ContractTableSkeleton";
import { DataGrid } from "@mui/x-data-grid";
import { FRONTEND_ROUTES } from "../../frontendRoutes";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import Scrollbar from "../../components/Scrollbar";
import TrafficOutlinedIcon from "@mui/icons-material/TrafficOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { laggy } from "../../utils/SWRLaggy";
import { translateString } from "../utils";
import { useKcRole } from "../../hooks/useKcRole";
import useSWR from "swr";

const pageItemCount = 100;

export function ContractTable() {
    const { isBasicRole } = useKcRole();

    const [page, setPage] = useState(0);
    const [sortState, setSortState] = useState([
        { field: "companyName", sort: "asc" },
    ]);
    const [filters, setFilters] = useSearchParams();

    const navigate = useNavigate();

    const filterParams = {
        status:
            filters.get("status") ??
            (isBasicRole ? "" : CONTRACT_STATUS.ORDERED),
        companyUuid: filters.get("companyUuid"),
        supervisorUuid: filters.get("supervisorUuid"),
        salespersonUuid: filters.get("salespersonUuid"),
    };

    const { data: users, error: usersFetchError } = useSWR(
        !isBasicRole ? `${BACKEND_ROUTES.USER}?sort=firstName` : null
    );

    const { data: companies, error: companiesFetchError } = useSWR(
        `${BACKEND_ROUTES.COMPANY}?sort=name`
    );

    const gridColDef = [
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            hideable: false,
            filterable: false,
            minWidth: 90,
            maxWidth: 90,
            flex: 0.5,
            disableClickEventBubbling: true,
            renderCell: (params) => (
                <>
                    <Tooltip title="View details">
                        <IconButton
                            sx={{
                                "&:hover": {
                                    backgroundColor: "primary.lighter",
                                },
                            }}
                            component={Link}
                            to={`/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT}/${params.row.id}`}
                        >
                            <VisibilityIcon fontSize="small" color="primary" />
                        </IconButton>
                    </Tooltip>
                    {!isBasicRole && (
                        <Tooltip title="Go to Traffic">
                            <IconButton
                                sx={{
                                    "&:hover": {
                                        backgroundColor: "primary.lighter",
                                    },
                                }}
                                component={Link}
                                to={`/${FRONTEND_ROUTES.TRAFFIC}/${FRONTEND_ROUTES.INBOUND_TRAFFIC}?contractUuid=${params.row.id}`}
                            >
                                <TrafficOutlinedIcon
                                    fontSize="small"
                                    color="primary"
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            ),
        },
        {
            field: "companyName",
            headerName: "Company name",
            filterable: false,
            minWidth: 180,
            flex: 3,
        },
        {
            field: "name",
            headerName: "Campaign name",
            filterable: false,
            minWidth: 250,
            flex: 3,
        },
        {
            field: "startDate",
            headerName: "Start date",
            filterable: false,
            minWidth: 120,
            flex: 2,
        },
        {
            field: "endDate",
            headerName: "End date",
            filterable: false,
            minWidth: 120,
            flex: 2,
        },
        ...(isBasicRole
            ? []
            : [
                  {
                      field: "supervisor",
                      headerName: "Campaign Manager",
                      filterable: false,
                      minWidth: 180,
                      flex: 2,
                  },
              ]),
        {
            field: "salesperson",
            headerName: "Project Lead",
            filterable: false,
            minWidth: 180,
            flex: 2,
        },
        ...(isBasicRole
            ? []
            : [
                  {
                      field: "reportDate",
                      headerName: "Report Date",
                      minWidth: 180,
                      flex: 2,
                      sortable: false,
                      filterable: false,
                  },
              ]),
    ];

    const searchParamsObject = {
        parentInfo: "true",
        limit: pageItemCount,
        offset: page * pageItemCount,
        ...(filterParams.status && { status: filterParams.status }),
        ...(filterParams.companyUuid && {
            companyUuid: filterParams.companyUuid,
        }),
        ...(filterParams.supervisorUuid && {
            supervisorUuid: filterParams.supervisorUuid,
        }),
        ...(filterParams.salespersonUuid && {
            salespersonUuid: filterParams.salespersonUuid,
        }),
        ...(sortState[0] && {
            sort: `${sortState[0].sort === "desc" ? "-" : ""}${translateString(
                sortState[0].field,
                {
                    supervisor: "Supervisor_firstName",
                    salesperson: "Salesperson_firstName",
                    companyName: "Company_name",
                }
            )}`,
        }),
    };

    // Fetch processes with pagination
    const {
        data: contracts,
        error: contractsFetchError,
        isLagging,
    } = useSWR(
        `${BACKEND_ROUTES.CONTRACT}?${new URLSearchParams(searchParamsObject)}`,
        { use: [laggy] }
    );

    const selectedItems = useMemo(
        () => ({
            company: companies
                ? companies.rows.find(
                      (company) => company.uuid === filterParams.companyUuid
                  )
                : null,
            supervisor: users
                ? users.find(
                      (user) => user.uuid === filterParams.supervisorUuid
                  )
                : null,
            salesperson: users
                ? users.find(
                      (user) => user.uuid === filterParams.salespersonUuid
                  )
                : null,
        }),
        [
            companies,
            users,
            filterParams.companyUuid,
            filterParams.supervisorUuid,
            filterParams.salespersonUuid,
        ]
    );

    const mergedFetchError =
        contractsFetchError ?? usersFetchError ?? companiesFetchError;
    if (mergedFetchError && !contracts)
        return <FetchErrorAlert error={mergedFetchError} />;
    if ((!users && !isBasicRole) || !contracts || !companies)
        return <ContractTableSkeleton />;

    const gridRows = contracts.rows.map((contract) => {
        return {
            id: contract.uuid,
            companyName: contract.Company.name,
            name: contract.name,
            startDate: contract.startDate,
            endDate: contract.endDate,
            supervisor: `${contract.Supervisor.firstName} ${contract.Supervisor.lastName}`,
            salesperson: `${contract.Salesperson.firstName} ${contract.Salesperson.lastName}`,
            reportDate: contract.reportDate,
        };
    });

    return (
        <Stack>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <Typography variant="h4" gutterBottom>
                    Campaigns
                </Typography>
                <Stack direction="row" spacing={1}>
                    {!isBasicRole && (
                        <IconButton
                            size="large"
                            onClick={() =>
                                navigate(
                                    `/${FRONTEND_ROUTES.ORDERS}/${FRONTEND_ROUTES.CONTRACT_CREATE}`
                                )
                            }
                        >
                            <AddCircleOutlinedIcon
                                fontSize="inherit"
                                color="primary"
                            />
                        </IconButton>
                    )}
                </Stack>
            </Stack>
            <Grid container spacing={1} mb={2}>
                {!isBasicRole && (
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            id="outlined-select-status"
                            select
                            label="Filter by status"
                            value={filterParams.status}
                            onChange={(event) => {
                                setFilters((prevState) => {
                                    const newSearchParams = new URLSearchParams(
                                        prevState
                                    );
                                    newSearchParams.set(
                                        "status",
                                        event.target.value
                                    );
                                    return newSearchParams;
                                });
                            }}
                        >
                            {Object.values(CONTRACT_STATUS).map((status) => (
                                <MenuItem key={status} value={status}>
                                    {status}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                )}
                <Grid item xs={3}>
                    <Autocomplete
                        id="company"
                        value={selectedItems.company ?? null}
                        options={companies.rows}
                        isOptionEqualToValue={(option, value) =>
                            option.uuid === value.uuid
                        }
                        fullWidth
                        getOptionLabel={(option) => option.name}
                        onChange={(_, value) => {
                            setFilters((prevState) => {
                                const newSearchParams = new URLSearchParams(
                                    prevState
                                );
                                if (value) {
                                    newSearchParams.set(
                                        "companyUuid",
                                        value.uuid
                                    );
                                } else {
                                    newSearchParams.delete("companyUuid");
                                }
                                return newSearchParams;
                            });
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Filter on company" />
                        )}
                    />
                </Grid>
                {!isBasicRole && (
                    <>
                        <Grid item xs={3}>
                            <Autocomplete
                                id="supervisor"
                                options={users}
                                value={selectedItems.supervisor ?? null}
                                isOptionEqualToValue={(option, value) =>
                                    option.uuid === value.uuid
                                }
                                fullWidth
                                getOptionLabel={(option) =>
                                    `${option.firstName} ${option.lastName}`
                                }
                                onChange={(_, value) => {
                                    setFilters((prevState) => {
                                        const newSearchParams =
                                            new URLSearchParams(prevState);
                                        if (value) {
                                            newSearchParams.set(
                                                "supervisorUuid",
                                                value.uuid
                                            );
                                        } else {
                                            newSearchParams.delete(
                                                "supervisorUuid"
                                            );
                                        }
                                        return newSearchParams;
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Filter on campaign manager"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                id="salesperson"
                                options={users}
                                value={selectedItems.salesperson ?? null}
                                isOptionEqualToValue={(option, value) =>
                                    option.uuid === value.uuid
                                }
                                fullWidth
                                getOptionLabel={(option) =>
                                    `${option.firstName} ${option.lastName}`
                                }
                                onChange={(_, value) => {
                                    setFilters((prevState) => {
                                        const newSearchParams =
                                            new URLSearchParams(prevState);
                                        if (value) {
                                            newSearchParams.set(
                                                "salespersonUuid",
                                                value.uuid
                                            );
                                        } else {
                                            newSearchParams.delete(
                                                "salespersonUuid"
                                            );
                                        }
                                        return newSearchParams;
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Filter on project lead"
                                    />
                                )}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
            <Card>
                <Stack spacing={2}>
                    <Scrollbar>
                        <DataGrid
                            autoHeight
                            rows={gridRows}
                            columns={gridColDef}
                            pageSizeOptions={[pageItemCount]}
                            initialState={{
                                sorting: {
                                    sortModel: sortState,
                                },
                            }}
                            paginationMode="server"
                            paginationModel={{
                                page,
                                pageSize: pageItemCount,
                            }}
                            onPaginationModelChange={(newPageModel) => {
                                setPage(newPageModel.page);
                            }}
                            rowCount={contracts.count}
                            sortingMode="server"
                            onSortModelChange={setSortState}
                            loading={isLagging}
                        />
                    </Scrollbar>
                </Stack>
            </Card>
        </Stack>
    );
}
