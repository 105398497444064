import L from "leaflet";

export const geomanMarkerIcon = L.icon({
    iconUrl: "/static/marker-icon-2x.png",
    shadowUrl: "/static/marker-shadow.png",
    iconSize: L.Icon.Default.prototype.options.iconSize,
    shadowSize: L.Icon.Default.prototype.options.shadowSize,
    iconAnchor: L.Icon.Default.prototype.options.iconAnchor,
    shadowAnchor: L.Icon.Default.prototype.options.shadowAnchor,
    popupAnchor: L.Icon.Default.prototype.options.popupAnchor,
});
