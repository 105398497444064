/**
 * Defines the default values for the parcellaire creation form
 * @param {boolean} isLiteral - Whether the plot map tool is used for literal or not
 * @returns {object} Default values for the parcellaire creation form
 */
export function getDefaultValuesParcellaireForm(isLiteral) {
    return isLiteral
        ? {
              xCount: 20,
              yCount: 4,
              latitude: 0,
              longitude: 0,
              xSize: 3,
              ySize: 1,
              xSpacing: 0.5,
              ySpacing: 0.2,
              angle: 0,
          }
        : {
              xCount: "",
              yCount: "",
              latitude: 0,
              longitude: 0,
              xSize: "",
              ySize: "",
              xSpacing: "",
              ySpacing: "",
              angle: 0,
          };
}
