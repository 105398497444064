export const FRONTEND_ROUTES = Object.freeze({
    /**
     * Orders
     */
    ORDERS: "orders",
    COMPANY: "companies",
    CONTRACT: "campaigns",
    CONTRACT_CREATE: "campaigns/create",
    SITE: "sites",

    /**
     * Monitoring
     */
    MONITORING: "monitoring",
    BOOKINGS: "bookings",
    ROUTING: "routing",
    MANIFEST: "manifest",

    /**
     * Traffic
     */
    TRAFFIC: "traffic",
    INBOUND_TRAFFIC: "inbound",
    INBOUND_TRAFFIC_DETAILS: "details",
    PROCESSING_TRAFFIC: "processing",
    ACQUISITION_REPORT: "acquisition-report",
    COMPLETED_TRAFFIC: "completed",

    /**
     * Parcellaire
     */
    PARCELLAIRE: "plotmap",
    PARCELLAIRE_LITERAL: "plotmap-literal",

    /**
     * Validation
     */
    VALIDATION: "validation",
    PROCESS: "processes",

    /**
     * Tables
     */
    GLOSSARY: "glossary",
    TRAIT: "traits",
    SENSOR: "sensors",
    SYSTEM_MODEL: "system-models",
    CROP: "crops",
});
