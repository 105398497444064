import {
    Button,
    Container,
    Dialog,
    DialogActions,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    Tab,
    Tabs,
    TextField,
    ToggleButton,
    Tooltip,
    Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { DYNAMIC_FEATURE_X_Y_FIELD_NAME } from "./utils/constants";
import DoneIcon from "@mui/icons-material/Done";
import { FieldBookTutorial } from "./FieldBookTutorial";
import { INPUT_VALIDATION } from "../../constants";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PropTypes from "prop-types";
import SearchIcon from "@mui/icons-material/Search";
import { TabPanel } from "../../components";
import { parcellaireCreationSubmitImport } from "./parcellaireCreationSubmitImport";
import { parcellaireCreationSubmitNoImport } from "./parcellaireCreationSubmitNoImport";
import { readCSVToArray } from "./utils/fileIoUtils";
import { useState } from "react";

ParcellaireCreationFormNoImport.propTypes = {
    setIsOpen: PropTypes.func,
    parcellaireDispatch: PropTypes.func.isRequired,
    mapRef: PropTypes.any.isRequired,
    setGeoJsonKey: PropTypes.func.isRequired,
    setBlueRectangleData: PropTypes.func.isRequired,
    isLiteral: PropTypes.bool,
    site: PropTypes.object,
    coordMarkerMode: PropTypes.bool.isRequired,
    setCoordMarkerMode: PropTypes.func.isRequired,
    openSnackbar: PropTypes.any.isRequired,
    setSelectableMetadata: PropTypes.func.isRequired,
};

export function ParcellaireCreationFormNoImport({
    setIsOpen,
    parcellaireDispatch,
    mapRef,
    setGeoJsonKey,
    setBlueRectangleData,
    isLiteral = false,
    site,
    coordMarkerMode,
    setCoordMarkerMode,
    openSnackbar,
    setSelectableMetadata,
}) {
    const {
        control,
        setValue,
        watch,
        register,
        handleSubmit,
        formState: { errors },
    } = useFormContext();

    const [location, setLocation] = useState("");

    const [openFieldBookTutorial, setOpenFieldBookTutorial] = useState(false);

    const [tabValue, setTabValue] = useState(0);

    const handleChange = (_, newValue) => {
        setTabValue(newValue);
    };

    const [xCountWatch, yCountWatch, csvDataWatch] = watch([
        "xCount",
        "yCount",
        "csvData",
    ]);

    const onSubmit = (payload) => {
        if (tabValue && isLiteral) {
            parcellaireCreationSubmitImport(
                payload,
                setIsOpen,
                parcellaireDispatch,
                mapRef,
                setGeoJsonKey,
                openSnackbar,
                isLiteral,
                site,
                setSelectableMetadata
            );
        } else {
            parcellaireCreationSubmitNoImport(
                payload,
                isLiteral ? null : setIsOpen,
                site,
                parcellaireDispatch,
                mapRef,
                setGeoJsonKey,
                setSelectableMetadata
            );
        }

        if (isLiteral) {
            setValue("selectedMetadata", {
                label: "x_y",
                id: DYNAMIC_FEATURE_X_Y_FIELD_NAME,
            });
        }

        setBlueRectangleData(null);
    };

    const plotCount =
        (xCountWatch ? xCountWatch : 1) * (yCountWatch ? yCountWatch : 1);

    const onNominatim = (query_name) => {
        if (!query_name) return;

        const request = new XMLHttpRequest();
        request.open(
            "GET",
            "https://nominatim.openstreetmap.org/search?q=" +
                encodeURI(query_name) +
                "&format=jsonv2&accept-language=fr-FR",
            true
        );
        request.responseType = "json";
        request.onload = function (event) {
            const results = event.target.response;
            if (!results.length) return;

            mapRef.current?.fitBounds([
                [results[0].boundingbox[0], results[0].boundingbox[2]],
                [results[0].boundingbox[1], results[0].boundingbox[3]],
            ]);
        };
        request.send();
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs="auto">
                            <TextField
                                label="Search location"
                                value={location}
                                onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                        onNominatim(location);
                                        event.preventDefault();
                                    }
                                }}
                                onChange={(event) => {
                                    setLocation(event.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton onClick={() => onNominatim(location)}>
                                <SearchIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Initial coordinates</Typography>
                        <Divider />
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        spacing={2}
                        justifyContent="space-between"
                    >
                        <Grid item xs={2}>
                            <ToggleButton
                                color="primary"
                                value={coordMarkerMode}
                                size="small"
                                selected={coordMarkerMode}
                                onChange={() => {
                                    setCoordMarkerMode((mode) => !mode);
                                }}
                            >
                                <Tooltip
                                    placement="left"
                                    title={
                                        <Typography>
                                            Place coordinates marker on map
                                        </Typography>
                                    }
                                >
                                    <LocationOnIcon />
                                </Tooltip>
                            </ToggleButton>
                        </Grid>
                        <Grid item xs={5}>
                            <Controller
                                control={control}
                                name="latitude"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        type="number"
                                        label="Latitude"
                                        id="latitude"
                                        inputRef={ref}
                                        error={Boolean(errors.latitude)}
                                        helperText={errors.latitude?.message}
                                        {...register("latitude", {
                                            valueAsNumber: true,
                                        })}
                                        inputProps={{
                                            step: "0.00000001",
                                            inputMode: "decimal",
                                        }}
                                    />
                                )}
                                rules={{
                                    required: INPUT_VALIDATION.REQUIRED,
                                    validate: (value) =>
                                        (value >= -90 && value <= 90) ||
                                        INPUT_VALIDATION.LATITUDE_INVALID,
                                }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <Controller
                                control={control}
                                name="longitude"
                                render={({ field: { ref, ...fieldProps } }) => (
                                    <TextField
                                        {...fieldProps}
                                        type="number"
                                        label="Longitude"
                                        id="longitude"
                                        inputRef={ref}
                                        error={Boolean(errors.longitude)}
                                        helperText={errors.longitude?.message}
                                        {...register("longitude", {
                                            valueAsNumber: true,
                                        })}
                                        inputProps={{
                                            step: "0.00000001",
                                            inputMode: "decimal",
                                        }}
                                    />
                                )}
                                rules={{
                                    required: INPUT_VALIDATION.REQUIRED,
                                    validate: (value) =>
                                        (value >= -180 && value <= 180) ||
                                        INPUT_VALIDATION.LONGITUDE_INVALID,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>Plot geometry</Typography>
                        <Divider />
                    </Grid>
                    {isLiteral ? (
                        <Container>
                            <Stack
                                direction="row"
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                                alignItems="center"
                            >
                                <Tabs
                                    value={tabValue}
                                    onChange={handleChange}
                                    sx={{ flexGrow: 1 }}
                                >
                                    <Tab label="Regular grid" />
                                    <Tab label="From field book" />
                                </Tabs>
                            </Stack>
                            <TabPanel value={tabValue} index={0}>
                                <Stack spacing={1}>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        spacing={2}
                                        justifyContent="space-between"
                                    >
                                        <Grid item xs={6}>
                                            <TextField
                                                type="number"
                                                label="X (columns)"
                                                id="xCount"
                                                error={Boolean(errors.xCount)}
                                                helperText={
                                                    errors.xCount?.message
                                                }
                                                {...register("xCount", {
                                                    required:
                                                        INPUT_VALIDATION.REQUIRED,
                                                    valueAsNumber: true,
                                                    validate: (value) =>
                                                        value > 0 ||
                                                        INPUT_VALIDATION.POSITIVE,
                                                })}
                                                inputProps={{
                                                    step: "1",
                                                    inputMode: "decimal",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                type="number"
                                                label="Y (rows)"
                                                id="yCount"
                                                error={Boolean(errors.yCount)}
                                                helperText={
                                                    errors.yCount?.message
                                                }
                                                {...register("yCount", {
                                                    required:
                                                        INPUT_VALIDATION.REQUIRED,
                                                    valueAsNumber: true,
                                                    validate: (value) =>
                                                        value > 0 ||
                                                        INPUT_VALIDATION.POSITIVE,
                                                })}
                                                inputProps={{
                                                    step: "1",
                                                    inputMode: "decimal",
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        justifyContent={"flex-end"}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{ color: "text.secondary" }}
                                        >
                                            Total: {plotCount} plot
                                            {plotCount > 1 && "s"}
                                        </Typography>
                                    </Grid>
                                </Stack>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Grid item xs="auto">
                                        <Button
                                            variant="contained"
                                            component="label"
                                            sx={{ textTransform: "none" }}
                                        >
                                            Import field book
                                            <input
                                                hidden
                                                type="file"
                                                accept=".csv"
                                                onChange={(event) => {
                                                    const csvFile =
                                                        event.target.files[0];

                                                    if (csvFile) {
                                                        readCSVToArray(
                                                            csvFile,
                                                            setValue,
                                                            true
                                                        );
                                                    }
                                                }}
                                            />
                                        </Button>
                                    </Grid>
                                    <Grid item xs={1}>
                                        {csvDataWatch && (
                                            <DoneIcon color="success" />
                                        )}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            variant="contained"
                                            component="label"
                                            sx={{ textTransform: "none" }}
                                            onClick={() => {
                                                setOpenFieldBookTutorial(true);
                                            }}
                                        >
                                            Tutorial
                                        </Button>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                        </Container>
                    ) : (
                        <>
                            <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                justifyContent="space-between"
                            >
                                <Grid item xs={6}>
                                    <TextField
                                        type="number"
                                        label="X (columns)"
                                        id="xCount"
                                        error={Boolean(errors.xCount)}
                                        helperText={errors.xCount?.message}
                                        {...register("xCount", {
                                            required: INPUT_VALIDATION.REQUIRED,
                                            valueAsNumber: true,
                                            validate: (value) =>
                                                value > 0 ||
                                                INPUT_VALIDATION.POSITIVE,
                                        })}
                                        inputProps={{
                                            step: "1",
                                            inputMode: "decimal",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        type="number"
                                        label="Y (rows)"
                                        id="yCount"
                                        error={Boolean(errors.yCount)}
                                        helperText={errors.yCount?.message}
                                        {...register("yCount", {
                                            required: INPUT_VALIDATION.REQUIRED,
                                            valueAsNumber: true,
                                            validate: (value) =>
                                                value > 0 ||
                                                INPUT_VALIDATION.POSITIVE,
                                        })}
                                        inputProps={{
                                            step: "1",
                                            inputMode: "decimal",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={12}
                                justifyContent={"flex-end"}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{ color: "text.secondary" }}
                                >
                                    Total: {plotCount} plot
                                    {plotCount > 1 && "s"}
                                </Typography>
                            </Grid>
                        </>
                    )}
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                type="number"
                                label="X size"
                                id="xSize"
                                error={Boolean(errors.xSize)}
                                helperText={errors.xSize?.message}
                                {...register("xSize", {
                                    required: INPUT_VALIDATION.REQUIRED,
                                    valueAsNumber: true,
                                    validate: (value) =>
                                        value > 0 || INPUT_VALIDATION.POSITIVE,
                                })}
                                inputProps={{
                                    step: "0.01",
                                    inputMode: "decimal",
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            m
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type="number"
                                label="Y size"
                                id="ySize"
                                error={Boolean(errors.ySize)}
                                helperText={errors.ySize?.message}
                                {...register("ySize", {
                                    required: INPUT_VALIDATION.REQUIRED,
                                    valueAsNumber: true,
                                    validate: (value) =>
                                        value > 0 || INPUT_VALIDATION.POSITIVE,
                                })}
                                inputProps={{
                                    step: "0.01",
                                    inputMode: "decimal",
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            m
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                type="number"
                                label="X spacing"
                                id="xSpacing"
                                error={Boolean(errors.xSpacing)}
                                helperText={errors.xSpacing?.message}
                                {...register("xSpacing", {
                                    required: INPUT_VALIDATION.REQUIRED,
                                    valueAsNumber: true,
                                    validate: (value) =>
                                        value >= 0 ||
                                        INPUT_VALIDATION.NON_NEGATIVE,
                                })}
                                inputProps={{
                                    step: "0.01",
                                    inputMode: "decimal",
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            m
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                type="number"
                                label="Y spacing"
                                id="ySpacing"
                                error={Boolean(errors.ySpacing)}
                                helperText={errors.ySpacing?.message}
                                {...register("ySpacing", {
                                    required: INPUT_VALIDATION.REQUIRED,
                                    valueAsNumber: true,
                                    validate: (value) =>
                                        value >= 0 ||
                                        INPUT_VALIDATION.NON_NEGATIVE,
                                })}
                                inputProps={{
                                    step: "0.01",
                                    inputMode: "decimal",
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            m
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                type="number"
                                label="Rotation angle"
                                id="angle"
                                error={Boolean(errors.angle)}
                                helperText={errors.angle?.message}
                                {...register("angle", {
                                    valueAsNumber: true,
                                    validate: (value) =>
                                        (value >= 0 && value <= 360) ||
                                        INPUT_VALIDATION.DEGREE_INVALID,
                                })}
                                inputProps={{
                                    step: "0.0000000001",
                                    inputMode: "decimal",
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            °
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <DialogActions>
                    <Button
                        variant="contained"
                        startIcon={<AddCircleIcon />}
                        type="submit"
                    >
                        Create
                    </Button>
                </DialogActions>
            </form>
            {isLiteral && (
                <Dialog open={openFieldBookTutorial} fullWidth maxWidth="md">
                    <FieldBookTutorial
                        closeModal={() => {
                            setOpenFieldBookTutorial(false);
                        }}
                    />
                </Dialog>
            )}
        </>
    );
}
