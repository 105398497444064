import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {
    MissionProtocolLiteral,
    MissionProtocolUav,
    exportProtocolPdfUav,
} from ".";
import { useFetch, useSnackbar } from "../../hooks";

import { BACKEND_ROUTES } from "../../backendRoutes";
import { FetchErrorAlert } from "../../components/FetchErrorAlert";
import { LoadingButton } from "@mui/lab";
import { MissionProtocolSkeleton } from "./MissionProtocolSkeleton";
import PropTypes from "prop-types";
import { SYSTEMS } from "../../constants";
import { useForm } from "react-hook-form";
import { useKcRole } from "../../hooks/useKcRole";
import { useMemo } from "react";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

const maxTraitCount = 7;

MissionProtocol.propTypes = {
    pipelineTemplateUuid: PropTypes.string.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export function MissionProtocol({ pipelineTemplateUuid, closeModal }) {
    const { isBasicRole } = useKcRole();

    const {
        data: pipelineTemplate,
        pipelineTemplateFetchError,
        mutate,
    } = useSWR(
        `${BACKEND_ROUTES.PIPELINE_TEMPLATE}/${pipelineTemplateUuid}?parentInfo=true&traitGroups=true&flightSequences=true&sop=true`
    );

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting, isDirty },
    } = useForm({
        // values replaces useEffect (the form will use defaultValues until the object given to values is fetched by useSWR)
        // here, we could also replace "pipelineTemplate" by "{ comment: pipelineTemplate?.comment }"
        values: pipelineTemplate,
        defaultValues: {
            comment: "",
        },
    });

    const { openSnackbar } = useSnackbar();

    const { patch } = useFetch();

    const { data: bbchStageData, bbchStageFetchError } = useSWRImmutable(
        BACKEND_ROUTES.BBCH_STAGE
    );

    const mergedFetchError = bbchStageFetchError ?? pipelineTemplateFetchError;

    /**
     * traitGroupDataTypeArrays is an object array containing all distinct trait group data types for this specific pipeline template
     * the tgdt are stored in arrays, separated per dataTypes, and each dataType is the key of the object (like this: {RGB: [...], Multispectral: [...]})
     * traitGroupDataTypeLength is the total count of all of these arrays
     */
    const [traitGroupDataTypeArrays, traitGroupDataTypeLength] = useMemo(() => {
        // this part filters duplicates without actually filtering
        const traitGroupDataTypeMap = new Map();
        pipelineTemplate?.PipelineTemplateTraitGroups.forEach((PTTG) => {
            traitGroupDataTypeMap.set(
                PTTG.TraitGroupDataType.uuid,
                PTTG.TraitGroupDataType
            );
        });

        const returnedObject = {};

        const filteredTraitGroupDataTypes = [...traitGroupDataTypeMap.values()];

        // we store each TGDT in its dataType param of the final object
        filteredTraitGroupDataTypes.forEach((TGDT) => {
            returnedObject[TGDT.dataType]
                ? returnedObject[TGDT.dataType].push(TGDT)
                : (returnedObject[TGDT.dataType] = [TGDT]);
        });

        return [returnedObject, filteredTraitGroupDataTypes.length];
    }, [pipelineTemplate]);

    if (mergedFetchError) return <FetchErrorAlert error={mergedFetchError} />;

    if (!pipelineTemplate || !bbchStageData) {
        return <MissionProtocolSkeleton />;
    }

    const isLiteral =
        pipelineTemplate.AcquisitionVector.SystemModel.system ===
        SYSTEMS.LITERAL;

    const onSubmit = async (payload) => {
        const newPipelineTemplate = await mutate(
            patch(
                `${BACKEND_ROUTES.PIPELINE_TEMPLATE}/${pipelineTemplateUuid}`,
                {
                    body: { comment: payload.comment },
                }
            ),
            {
                populateCache: (
                    updatedPipelineTemplate,
                    currentPipelineTemplate
                ) => ({
                    ...currentPipelineTemplate,
                    comment: updatedPipelineTemplate.comment,
                }),
                optimisticData: {
                    ...pipelineTemplate,
                    comment: payload.comment,
                },
                /**
                 * revalidate: true if we want the cache to be revalidated after the update is resolved
                 * since the backend validates the data (and there's rollbackOnError if the backend doesn't validate the data),
                 * it would be pointless to revalidate a second time after the update is successful
                 */
                revalidate: false,
                rollbackOnError: true,
            }
        );
        if (newPipelineTemplate) {
            openSnackbar(
                `Comment for this pipeline template has been updated successfully.`,
                "success"
            );
        }
    };

    const displayTraits = !(traitGroupDataTypeLength > maxTraitCount);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>
                Data Acquisition Protocol: {pipelineTemplate.name}
            </DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                {isLiteral ? (
                    <MissionProtocolLiteral
                        pipelineTemplate={pipelineTemplate}
                        traitGroupDataTypeArrays={traitGroupDataTypeArrays}
                    />
                ) : (
                    <MissionProtocolUav
                        pipelineTemplate={pipelineTemplate}
                        bbchStageData={bbchStageData}
                        traitGroupDataTypeArrays={traitGroupDataTypeArrays}
                        displayTraits={displayTraits}
                        maxTraitCount={maxTraitCount}
                    />
                )}
                <Stack mt={4} direction="column">
                    <Typography>Comments</Typography>
                    <TextField
                        id="comment"
                        fullWidth
                        type="text"
                        error={Boolean(errors.comment)}
                        helperText={errors.comment?.message}
                        disabled={isBasicRole}
                        multiline
                        placeholder={
                            isBasicRole
                                ? "No comment"
                                : "Type additional information here"
                        }
                        rows={6}
                        InputProps={{ "white-space": "pre-wrap" }}
                        {...register("comment")}
                    />
                </Stack>
            </DialogContent>
            <Divider variant="middle" />
            <DialogActions sx={{ px: 2 }}>
                <Button type="button" onClick={closeModal}>
                    Close
                </Button>
                {!isLiteral && (
                    <Button
                        variant="outlined"
                        onClick={() =>
                            exportProtocolPdfUav(
                                pipelineTemplate,
                                displayTraits
                            )
                        }
                    >
                        Export to PDF
                    </Button>
                )}
                {!isBasicRole && (
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={!isDirty}
                    >
                        Save
                    </LoadingButton>
                )}
            </DialogActions>
        </form>
    );
}
