import { Card, Skeleton } from "@mui/material";

export const ContractDetailsSkeleton = () => {
    return (
        <Card>
            <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height="50vh"
            />
        </Card>
    );
};
