import { PDFDocument, StandardFonts } from "pdf-lib";

import { DATATYPES_ENUM } from "../../constants";
import download from "downloadjs";
import html2canvas from "html2canvas";

export async function exportProtocolPdfUav(pipelineTemplate, displayTraits) {
    // please refer to https://pdf-lib.js.org/
    const canvas = await html2canvas(
        document.getElementById(pipelineTemplate.uuid),
        {
            // onclone is a callback function which is called when the Document has been cloned for rendering
            // it can be used to modify the contents that will be rendered without affecting the original source document
            // here, we change the display of the cloned document so that html2canvas can render it on its side without rendering it for the user
            onclone: function (clonedDoc) {
                clonedDoc.getElementById(pipelineTemplate.uuid).style.display =
                    "block";
            },
            windowWidth: 1620,
            windowHeight: 920,
            scale: 1.25,
        }
    );

    const canvasSummary = await html2canvas(
        document.getElementById(`${pipelineTemplate.uuid}_summary`),
        {
            // onClone can be used to update rendered content without updating the original document
            // here, we change the display of the cloned document so that html2canvas can render it on its side without rendering it for the user
            onclone: function (clonedDoc) {
                clonedDoc.getElementById(
                    `${pipelineTemplate.uuid}_summary`
                ).style.display = "block";
            },
            windowWidth: 1620,
            windowHeight: 920,
            scale: 1.25,
        }
    );

    const pdfTemplateURL = pipelineTemplate.AcquisitionVector.SensorBundles.map(
        (bundle) => bundle.Sensor.dataType
    ).includes(DATATYPES_ENUM.MULTISPECTRAL)
        ? displayTraits
            ? "/static/SOP_PDF_Template_MS_standard.pdf"
            : "/static/SOP_PDF_Template_MS_extended.pdf"
        : displayTraits
          ? "/static/SOP_PDF_Template_RGB_standard.pdf"
          : "/static/SOP_PDF_Template_RGB_extended.pdf";

    const pdfTemplate = await (await fetch(pdfTemplateURL)).arrayBuffer();
    const pdfDoc = await PDFDocument.load(pdfTemplate);

    const pngImage = await pdfDoc.embedPng(canvas.toDataURL("image/png"));

    const pngImageSummary = await pdfDoc.embedPng(
        canvasSummary.toDataURL("image/png")
    );

    const pages = pdfDoc.getPages();

    // absoluteHeight is either two times the height of the image OR 1920 pixels (whichever is smaller) so the picture doesn't get stretched too much
    // values were picked through trial and error
    const absoluteHeight =
        pngImage.height * 2 < 1920 ? pngImage.height * 2 : 1920;

    pages[1].drawImage(pngImage, {
        x: pages[1].getWidth() / 2 - 3230 / 2,
        y: 200 + 1920 - absoluteHeight,
        width: 3230,
        height: absoluteHeight,
    });

    const absoluteHeightSummary =
        pngImageSummary.height * 2 < 1850 ? pngImageSummary.height * 2 : 1850;

    pages[0].drawImage(pngImageSummary, {
        x: (3 * pages[0].getWidth()) / 4 - 1500 / 2,
        y: 70 + 1920 - absoluteHeightSummary,
        width: 1500,
        height: absoluteHeightSummary,
    });

    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const isCompanyNameTooLong = Boolean(
        pipelineTemplate.Contract.Company.name.length > 34
    );

    const isContractNameTooLong = Boolean(
        pipelineTemplate.Contract.name.length > 15
    );

    pages.forEach((page) => {
        const pageWidth = page.getWidth();

        page.drawText(pipelineTemplate.Contract.Company.name, {
            x: isCompanyNameTooLong
                ? pageWidth / 2 - pageWidth / 6 - pageWidth / 27
                : pageWidth / 2 -
                  pipelineTemplate.Contract.Company.name.length * 20 -
                  pipelineTemplate.Contract.name.length * 10,
            y: page.getHeight() - 170,
            size: 80,
            font: helveticaFont,
            lineHeight: 90,
            maxWidth: pageWidth / 3,
        });

        page.drawText(
            `Data Acquisition Protocol - ${pipelineTemplate.Contract.name}`,
            {
                x:
                    (4 * pageWidth) / 5 -
                    150 -
                    (isContractNameTooLong
                        ? pageWidth / 9
                        : pipelineTemplate.Contract.name.length * 24),
                y: page.getHeight() - 170,
                size: 60,
                font: helveticaFont,
                lineHeight: 70,
                maxWidth: pageWidth / 3,
            }
        );
    });

    if (!displayTraits) {
        const tableCanvas = await html2canvas(
            document.getElementById(`${pipelineTemplate.uuid}_table`),
            {
                onclone: function (clonedDoc) {
                    clonedDoc.getElementById(
                        `${pipelineTemplate.uuid}_table`
                    ).style.display = "block";
                },
                windowWidth: 1620,
                windowHeight: 920,
                scale: 2.17,
            }
        );

        const tablePngImage = await pdfDoc.embedPng(
            tableCanvas.toDataURL("image/png")
        );

        const absoluteHeightTable =
            tablePngImage.height * 2 < 1920 ? tablePngImage.height * 2 : 1920;

        pages[2].drawImage(tablePngImage, {
            x: pages[1].getWidth() / 2 - 3230 / 2,
            y: 200 + 1920 - absoluteHeightTable,
            width: 3230,
            height: absoluteHeightTable,
        });
    }

    const savedPdf = await pdfDoc.save();

    // need to ask around to find out what template for the name of the file we want to use
    // for now it's MissionProtocol_CompanyName_ContractName_PipelineTemplateUuid.pdf
    download(
        savedPdf,
        `MissionProtocol_${pipelineTemplate.Contract.Company.name}_${pipelineTemplate.Contract.name}_${pipelineTemplate.uuid}.pdf`,
        "application/pdf"
    );
}
