import { Controller, useWatch } from "react-hook-form";
import { Divider, Grid, MenuItem, TextField, Typography } from "@mui/material";
import {
    INPUT_VALIDATION,
    PIPELINE_TEMPLATE_LITERAL_PRESETS_TGDTS,
} from "../../../constants";

import PropTypes from "prop-types";

PipelineTemplatePresetForm.propTypes = {
    control: PropTypes.object.isRequired,
    traitGroupDataTypeData: PropTypes.object.isRequired,
};

export function PipelineTemplatePresetForm({
    control,
    errors,
    traitGroupDataTypeData,
}) {
    const presetWatch = useWatch({
        name: "preset",
        control,
    });

    const filteredTraitGropDataTypes = {};

    console.log(
        Object.entries(PIPELINE_TEMPLATE_LITERAL_PRESETS_TGDTS),
        presetWatch,
        PIPELINE_TEMPLATE_LITERAL_PRESETS_TGDTS[presetWatch]
    );

    if (presetWatch)
        PIPELINE_TEMPLATE_LITERAL_PRESETS_TGDTS[presetWatch].tgdtArray.forEach(
            (tgdtUuid) => {
                const fullTGDT = traitGroupDataTypeData.rows.find(
                    (tgdt) => tgdt.uuid === tgdtUuid
                );
                if (!filteredTraitGropDataTypes[fullTGDT.dataType])
                    filteredTraitGropDataTypes[fullTGDT.dataType] = [];
                filteredTraitGropDataTypes[fullTGDT.dataType].push(fullTGDT);
            }
        );

    return (
        <Grid item xs={12} container spacing={1} alignContent="center">
            <Grid item xs={12}>
                <Typography>Select a preset</Typography>
                <Divider />
            </Grid>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="preset"
                    render={({ field: { ref, ...fieldProps } }) => (
                        <TextField
                            {...fieldProps}
                            fullWidth
                            id="preset"
                            select
                            label="Preset"
                            inputRef={ref}
                            error={Boolean(errors.preset)}
                            helperText={errors.preset?.message}
                        >
                            {Object.entries(
                                PIPELINE_TEMPLATE_LITERAL_PRESETS_TGDTS
                            ).map((preset) => (
                                <MenuItem key={preset[0]} value={preset[0]}>
                                    {preset[1].label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                    rules={{
                        required: INPUT_VALIDATION.REQUIRED,
                    }}
                />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={12}>
                {Object.keys(filteredTraitGropDataTypes).map((dataType) => {
                    return (
                        <Grid key={dataType} item container spacing={0.5}>
                            <Grid item xs={12}>
                                <Typography
                                    sx={{
                                        fontStyle: "italic",
                                        color: "grey",
                                    }}
                                >
                                    <strong>{`Selected traits (${dataType})`}</strong>
                                </Typography>
                                <Divider />
                            </Grid>

                            <Grid item xs={12} key={dataType}>
                                <Typography>
                                    {filteredTraitGropDataTypes[dataType].map(
                                        (TGDT, index) =>
                                            `${index !== 0 ? " ; " : ""}${TGDT.TraitGroup.name}`
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
}
