import {
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
} from "@mui/material";

import PropTypes from "prop-types";
import download from "downloadjs";

FieldBookTutorial.propTypes = {
    closeModal: PropTypes.func.isRequired,
};

export function FieldBookTutorial({ closeModal }) {
    const downloadTemplate = () => {
        download("/static/field_book_template.csv");
    };

    return (
        <>
            <DialogTitle>Field Book</DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} sx={{ pb: 2 }}>
                        <img
                            src={
                                "/static/illustrations/field_book_illustration.png"
                            }
                            alt={"Field Book explanation"}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={closeModal}>
                    Close
                </Button>
                <Button
                    type="button"
                    variant="outlined"
                    onClick={downloadTemplate}
                >
                    Download template
                </Button>
            </DialogActions>
        </>
    );
}
