import { Divider, Grid, Typography } from "@mui/material";

import PropTypes from "prop-types";

MissionProtocolStageTableLiteral.propTypes = {
    traitGroupDataTypeArrays: PropTypes.object.isRequired,
};

export function MissionProtocolStageTableLiteral({ traitGroupDataTypeArrays }) {
    return (
        <Grid container spacing={2}>
            {Object.keys(traitGroupDataTypeArrays).map((dataType) => {
                return (
                    <Grid key={dataType} item container spacing={0.5}>
                        <Grid item xs={12}>
                            <Typography
                                sx={{ fontStyle: "italic", color: "grey" }}
                            >
                                <strong>{`Selected traits (${dataType})`}</strong>
                            </Typography>
                            <Divider />
                        </Grid>

                        {traitGroupDataTypeArrays[dataType].map((TGDT) => (
                            <Grid item xs={12} key={TGDT.uuid}>
                                <Typography>{TGDT.TraitGroup.name}</Typography>
                            </Grid>
                        ))}
                    </Grid>
                );
            })}
        </Grid>
    );
}
