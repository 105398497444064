import { DYNAMIC_FEATURE_ID_FIELD_NAME } from "./utils/constants";
import { booleanContains } from "@turf/turf";
import { geoJSONUtil } from "./utils/geoJSONUtil";

export const PARCELLAIRE_REDUCER_ACTIONS = Object.freeze({
    CLEAR_ALL_GEOJSON: "clearAllGeoJson",
    CHECKPOINT: "checkpoint",
    GO_TO_CHECKPOINT: "goToCheckpoint",
    PM_EDIT: "pmEdit",
    METADATA: "metadata",
    GEOJSON: "geoJson",
    SELECT: "select",
    LOCK: "lock",
    UNLOCK: "unlock",
    DELETE: "delete",
});

export const initialParcellaireState = {
    initialGeoJsonData: null,
    checkpointGeoJsonData: null,
    transformedGeoJson: null,
    transformModeVectors: [],
    selectedLayers: [],
    lockedLayers: [],
    metadata: null,
};

export function parcellaireReducer(state, action) {
    const currentGeoJson = state?.transformedGeoJson;

    switch (action.type) {
        case PARCELLAIRE_REDUCER_ACTIONS.CLEAR_ALL_GEOJSON:
            return {
                ...state,
                initialGeoJsonData: null,
                checkpointGeoJsonData: null,
                transformedGeoJson: null,
                transformModeVectors: [],
                selectedLayers: [],
                lockedLayers: [],
            };
        case PARCELLAIRE_REDUCER_ACTIONS.CHECKPOINT:
            return {
                ...state,
                checkpointGeoJsonData: currentGeoJson,
                transformModeVectors: [],
                selectedLayers: [],
            };
        case PARCELLAIRE_REDUCER_ACTIONS.GO_TO_CHECKPOINT:
            return {
                ...state,
                selectedLayers: [],
                transformModeVectors: [],
                transformedGeoJson: state.checkpointGeoJsonData,
            };
        case PARCELLAIRE_REDUCER_ACTIONS.PM_EDIT: {
            return action.transform;
        }
        case PARCELLAIRE_REDUCER_ACTIONS.METADATA:
            // metadata is required
            return {
                ...state,
                metadata: action.metadata,
            };
        case PARCELLAIRE_REDUCER_ACTIONS.GEOJSON:
            // geoJson is required
            return {
                ...state,
                transformModeVectors: [],
                selectedLayers: [],
                lockedLayers: [],
                initialGeoJsonData: action.geoJson,
                checkpointGeoJsonData: action.geoJson,
                transformedGeoJson: action.geoJson,
            };
        case PARCELLAIRE_REDUCER_ACTIONS.SELECT:
            // e, geoJsonSelect, allLayers is required
            return {
                ...state,
                selectedLayers: action.allLayers.filter(
                    (layer) =>
                        layer !== action.e.layer &&
                        layer.feature &&
                        booleanContains(
                            action.geoJsonSelect,
                            layer.toGeoJSON()
                        ) &&
                        !state.lockedLayers.find(
                            (lockedLayer) =>
                                geoJSONUtil.getIdOfFeature(layer.feature) ===
                                geoJSONUtil.getIdOfFeature(lockedLayer.feature)
                        )
                ),
                transformModeVectors: [],
                checkpointGeoJsonData: currentGeoJson,
            };
        case PARCELLAIRE_REDUCER_ACTIONS.LOCK:
            return {
                ...state,
                selectedLayers: [],
                lockedLayers: [...state.lockedLayers, ...state.selectedLayers],
                transformModeVectors: [],
                checkpointGeoJsonData: currentGeoJson,
            };
        case PARCELLAIRE_REDUCER_ACTIONS.UNLOCK:
            return {
                ...state,
                selectedLayers: [],
                lockedLayers: [],
                transformModeVectors: [],
                checkpointGeoJsonData: currentGeoJson,
            };
        case PARCELLAIRE_REDUCER_ACTIONS.DELETE:
            if (state.selectedLayers.length) {
                return {
                    ...state,
                    transformModeVectors: [],
                    selectedLayers: [],
                    checkpointGeoJsonData: {
                        ...currentGeoJson,
                        features: currentGeoJson.features.filter(
                            (feature) =>
                                !state.selectedLayers.some(
                                    (layer) =>
                                        layer.feature.properties[
                                            DYNAMIC_FEATURE_ID_FIELD_NAME
                                        ] ===
                                        feature.properties[
                                            DYNAMIC_FEATURE_ID_FIELD_NAME
                                        ]
                                )
                        ),
                    },
                    transformedGeoJson: {
                        ...currentGeoJson,
                        features: currentGeoJson.features.filter(
                            (feature) =>
                                !state.selectedLayers.some(
                                    (layer) =>
                                        layer.feature.properties[
                                            DYNAMIC_FEATURE_ID_FIELD_NAME
                                        ] ===
                                        feature.properties[
                                            DYNAMIC_FEATURE_ID_FIELD_NAME
                                        ]
                                )
                        ),
                    },
                };
            } else return state;
        default:
            throw new Error("Unknown action: " + action.type);
    }
}
