import L from "leaflet";

export function removeAllCoordinateMarkers(mapOrContainer) {
    mapOrContainer.pm
        .getGeomanLayers()
        .filter(
            (layer) =>
                layer instanceof L.Marker &&
                layer.options.customType !== "idLabel"
        )
        .forEach((layer) => {
            layer.remove();
        });
}
