import {
    Autocomplete,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputAdornment,
    TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { BACKEND_ROUTES } from "../../backendRoutes";
import { INPUT_VALIDATION } from "../../constants";
import PropTypes from "prop-types";
import download from "downloadjs";
import { removeToolId } from "./utils/fileIoUtils";
import { useFetch } from "../../hooks";

ParcellaireLiteralMetadataForm.propTypes = {
    setIsOpen: PropTypes.func.isRequired,
    parcellaireState: PropTypes.object.isRequired,
    siteUuid: PropTypes.string.isRequired,
    openSnackbar: PropTypes.func.isRequired,
    site: PropTypes.object.isRequired,
    filteredPipelineTemplates: PropTypes.array.isRequired,
};

export function ParcellaireLiteralMetadataForm({
    setIsOpen,
    parcellaireState,
    siteUuid,
    openSnackbar,
    site,
    filteredPipelineTemplates,
}) {
    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        defaultValues: {
            pipelineTemplate:
                filteredPipelineTemplates.length === 1
                    ? filteredPipelineTemplates[0]
                    : null,
            interRank: null,
        },
    });

    const { get, post } = useFetch();

    const onSubmit = async (payload) => {
        try {
            const savedParcellaire = removeToolId(
                parcellaireState.transformedGeoJson
            );
            savedParcellaire.metadata = {
                inter_row_spacing: payload.interRowSpacing,
                hcc_pt_uuid: payload.pipelineTemplate.uuid,
                hcc_site_uuid: siteUuid,
                cloverfield_site_uuid: site.cloverfieldUuid,
                field: site.name,
                experiment: site.name,
                experimentId: siteUuid,
                campaign: site.Contract.name,
                nationalInfrastructure: site.Contract.Company.name,
                CropId: payload.pipelineTemplate.cropUuid,
            };
            download(
                JSON.stringify(savedParcellaire),
                "plotmap.geojson",
                "text/json"
            );
            const experimentFetchResult = await get(
                `${BACKEND_ROUTES.EXPERIMENT}?siteUuid=${siteUuid}&name=${site.name}&cropUuid=${payload.pipelineTemplate.cropUuid}`
            );

            if (experimentFetchResult.count === 0) {
                await post(`${BACKEND_ROUTES.SITE}/${siteUuid}/experiments`, {
                    body: {
                        cropUuid: payload.pipelineTemplate.cropUuid,
                        name: site.name,
                    },
                });
            }

            setIsOpen(false);
        } catch (error) {
            openSnackbar(
                "Something went wrong. Please try again later.",
                "error"
            );
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Export plot map</DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="pipelineTemplate"
                            render={({
                                field: { ref, onChange, ...fieldProps },
                            }) => (
                                <Autocomplete
                                    {...fieldProps}
                                    id="pipelineTemplate"
                                    options={filteredPipelineTemplates}
                                    isOptionEqualToValue={(option, value) =>
                                        option.uuid === value.uuid
                                    }
                                    getOptionLabel={(option) => option.name}
                                    onChange={(_, value) => {
                                        onChange(value);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            inputRef={ref}
                                            label="Pipeline Template"
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            type="number"
                            label="Inter-Row Spacing"
                            id="interRowSpacing"
                            error={Boolean(errors.interRowSpacing)}
                            helperText={errors.interRowSpacing?.message}
                            {...register("interRowSpacing", {
                                required: INPUT_VALIDATION.REQUIRED,
                                valueAsNumber: true,
                                validate: (value) =>
                                    value > 0 || INPUT_VALIDATION.POSITIVE,
                            })}
                            inputProps={{
                                step: "0.01",
                                inputMode: "decimal",
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        m
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button type="button" onClick={() => setIsOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    startIcon={<AddCircleIcon />}
                    type="submit"
                >
                    Export
                </Button>
            </DialogActions>
        </form>
    );
}
