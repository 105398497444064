const MAIN_ROOT = process.env.REACT_APP_API_URL;
const USERS_API_ROOT = `${MAIN_ROOT}/users`;
const AWS_ROOT = `${MAIN_ROOT}/aws`;

export const BACKEND_ROUTES = {
    /**
     * AWS
     */
    PUBLIC_SIGNED_URL: `${AWS_ROOT}/signed-url`,
    PUBLIC_FILE_LIST: `${AWS_ROOT}/file-list`,

    /**
     * User
     */
    USER: `${USERS_API_ROOT}`,
    MY_INFO: `${USERS_API_ROOT}/my-info`,

    /**
     * Main api
     */
    COMPANY: `${MAIN_ROOT}/companies`,
    CONTRACT: `${MAIN_ROOT}/contracts`,
    PROCESS: `${MAIN_ROOT}/processes`,
    MISSION: `${MAIN_ROOT}/missions`,
    MISSION_MODEL: `${MAIN_ROOT}/mission-models`,
    REGION: `${MAIN_ROOT}/regions`,
    SEGMENT: `${MAIN_ROOT}/segments`,
    PIPELINE: `${MAIN_ROOT}/pipelines`,
    PIPELINE_INSTANCE: `${MAIN_ROOT}/pipeline-instances`,
    PIPELINE_TEMPLATE: `${MAIN_ROOT}/pipeline-templates`,
    PIPELINE_TEMPLATE_CALENDAR: `${MAIN_ROOT}/pipeline-template-calendars`,
    CROP: `${MAIN_ROOT}/crops`,
    SENSOR: `${MAIN_ROOT}/sensors`,
    SYSTEM_MODEL: `${MAIN_ROOT}/system-models`,
    ACQUISITION_VECTOR: `${MAIN_ROOT}/acquisition-vectors`,
    TRAIT: `${MAIN_ROOT}/traits`,
    TRAIT_GROUP: `${MAIN_ROOT}/trait-groups`,
    TRAIT_GROUP_DATA_TYPE: `${MAIN_ROOT}/trait-group-data-types`,
    BBCH_STAGE: `${MAIN_ROOT}/bbch-stages`,
    PLANT_SCALE: `${MAIN_ROOT}/plant-scales`,
    PLANT_PROFILE: `${MAIN_ROOT}/plant-profiles`,
    REFERENCE_OBJECT: `${MAIN_ROOT}/reference-objects`,
    EXPERIMENT: `${MAIN_ROOT}/experiments`,
    EXPERIMENT_MISSION: `${MAIN_ROOT}/experiment-missions`,
    ASSOCIATED_TRAIT_GROUP: `${MAIN_ROOT}/associated-trait-groups`,
    SITE: `${MAIN_ROOT}/sites`,
    RAW_DATASET: `${MAIN_ROOT}/raw-datasets`,
    MODULE: `${MAIN_ROOT}/modules`,
    MODULE_VERSION: `${MAIN_ROOT}/module-versions`,
    OUTPUT_BINARY_DATA_SINGLE: `${MAIN_ROOT}/output-binary-data`,
    OUTPUT_BINARY_DATA_LIST: `/output-binary-data`, // processes/{uuid}/output-binary-data or mission-features/{uuid}/output-binary-data

    // cloverfield
    CF_UPLOAD_TEMPLATES: `${MAIN_ROOT}/cloverfield/upload-templates`,
};
