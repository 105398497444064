import { Container, Stack, Tab, Tabs } from "@mui/material";
import {
    ParcellaireCreationFormImport,
    ParcellaireCreationFormNoImport,
} from ".";

import PropTypes from "prop-types";
import { TabPanel } from "../../components";
import { useState } from "react";

ParcellaireCreationContainer.propTypes = {
    setIsOpen: PropTypes.func.isRequired,
    parcellaireDispatch: PropTypes.func.isRequired,
    mapRef: PropTypes.any.isRequired,
    setGeoJsonKey: PropTypes.func.isRequired,
    openSnackbar: PropTypes.func.isRequired,
    setBlueRectangleData: PropTypes.func.isRequired,
    coordMarkerMode: PropTypes.bool.isRequired,
    setCoordMarkerMode: PropTypes.func.isRequired,
    setSelectableMetadata: PropTypes.func.isRequired,
};

export function ParcellaireCreationContainer({
    setIsOpen,
    parcellaireDispatch,
    mapRef,
    setGeoJsonKey,
    openSnackbar,
    setBlueRectangleData,
    coordMarkerMode,
    setCoordMarkerMode,
    setValue,
    setSelectableMetadata,
}) {
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (_, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Container maxWidth="xl" sx={{ bgcolor: "background.default" }}>
            <Stack
                direction="row"
                sx={{ borderBottom: 1, borderColor: "divider" }}
                alignItems="center"
            >
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    sx={{ flexGrow: 1 }}
                >
                    <Tab label="Import Data" />
                    <Tab label="From Scratch" />
                </Tabs>
            </Stack>
            <TabPanel value={tabValue} index={0}>
                <ParcellaireCreationFormImport
                    setIsOpen={setIsOpen}
                    parcellaireDispatch={parcellaireDispatch}
                    mapRef={mapRef}
                    setGeoJsonKey={setGeoJsonKey}
                    openSnackbar={openSnackbar}
                    setBlueRectangleData={setBlueRectangleData}
                    setSelectableMetadata={setSelectableMetadata}
                />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <ParcellaireCreationFormNoImport
                    setIsOpen={setIsOpen}
                    parcellaireDispatch={parcellaireDispatch}
                    mapRef={mapRef}
                    setGeoJsonKey={setGeoJsonKey}
                    setBlueRectangleData={setBlueRectangleData}
                    coordMarkerMode={coordMarkerMode}
                    setCoordMarkerMode={setCoordMarkerMode}
                    setValue={setValue}
                    openSnackbar={openSnackbar}
                    setSelectableMetadata={setSelectableMetadata}
                />
            </TabPanel>
        </Container>
    );
}
